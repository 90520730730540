import React, { useState, useEffect, Fragment } from "react";
import "./Signup.css";
import Registration from "../Registration/Registration";

import { useNavigate } from "react-router-dom";

const Signup = (props) => {
  const navigate = useNavigate();

  if(props.isLoggedin)
  {
    navigate("/home")
  }
  return (
    <div>
      {!props.isLoggedin &&
      <Registration handleSuccessfulAuth={props.handleSuccessfulAuth} DEBUG={props.DEBUG}/>
      }
      

    </div>
  );
};

export default Signup;
