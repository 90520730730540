import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar } from "@mui/material";
//import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function Upload({
  handleClose,
  open,
  handleLogoutClick, handleImageUpload, handleUploadFile,handleFileSelect,setImageFile, image
}) {
  

 
  
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          background: "transparent !important",
          "& .MuiBackdrop-root-MuiModal-backdrop": {
            backgroundColor: "none",
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Upload Image
          </Typography>
          <form onSubmit={handleImageUpload}>
            <center>
          <Avatar
              alt={"Unknown"}
              src={image}
              sx={{
                width: 150,
                height: 150,
                color: (theme) => theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer",
                mt: 3,
                mb: 2
                
              }}

            />
            </center>
            <input type="file" onChange={handleFileSelect}/>
           
           <center>
            <Button
            type="button"
            variant="contained"
            onClick={handleUploadFile}
           
            sx={{
              py: 1.5,
              mt: 2,
              background: "#1877f2 !important",
              color: "#fff",
            }}
          >
            Upload Avatar
          </Button>
          </center>
          
          </form>
          
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              border: "2px solid #000",
              fontWeight: "bold",
              background: "#000 !important",
              width: "100%",
              py: 1,
              fontSize: 18,
              mt: 3,
            }}
            onClick={() => {
                          handleClose();
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
