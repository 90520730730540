import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Collapsible from "react-collapsible";
import axios from "axios";
import Cookies from "universal-cookie";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Summary.css";
import { linkHandler, timeSince } from "../Functions/Functions";
import Button from "@mui/material/Button";
import TeamLogo from "../TeamLogo/TeamLogo"
import { useStateValue } from "../../StateProvider"



const Summary = (props) => {
  const [state,dispatch] = useStateValue();
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const [lumps, setLumps] = useState([]);
  const [sources,setSources] = useState([]);
  let server
  const DEBUG = props.DEBUG
  
  server = "https://dm1r1mwnqdyan.cloudfront.net"
  const cookies = new Cookies();
 
  const [loaded, setLoaded] = useState(false);

  const getLumps = (sourceID) => {
      return lumps
      .filter((lump) => lump.source_id === sourceID)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() -
          new Date(a.created_at).getTime()
      );
  };

  const getSources = () => {
    if (sources.length === 1)
    {
      return sources
    }
   else
   {
    return sources.sort(function(a, b){
      if(a.username < b.username) { return -1; }
      if(a.username > b.username) { return 1; }
      return 0;
  });
   }
       
  };

  
  useEffect(() => {
    let temp
    temp = cookies.get("token");
   
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/daily/${temp}`;
    if (DEBUG === 1) console.log ("temp",temp,url)
    if (DEBUG === 1) console.log("State in Summary",state)

    if (state.lumps.length === 0 && state.sources.length === 0)
    {
      axios
      .get(url,{ headers: {
        'Access-Control-Allow-Origin': '*',
        'crossdomain': true,
        'Origin': 'chunkysports.com'
      }})
      .then((response) => {
        if (DEBUG === 1) console.log("Summary Response",response)
        props.setUser(response.data)
        setLumps(response.data.lumps)
        setSources(response.data.sources)
        setLoaded(true);
        //console.log(response.data.data);
      })
      .catch((response) => console.log(response));
    }
    else
    {
      setLumps(state.lumps)
      setSources(state.sources)
      setLoaded(true)
      console.log("Added from data level")
    }
    


  }, []);

  if (DEBUG === 1) console.log("Sources in Sources", sources)

  const showSummary = () => {
   

    return (
      <>
        {loaded &&
          getSources().map((source) => (
            <Collapsible
              trigger={<TeamLogo first={source.first_name} 
              last={source.last_name}
              username={source.username}
              server={server}
              logo={source.logo50}
              />            
               }
              key={source.id}
              transitionTime="50"
            >
              <ul>
                {getLumps(parseInt(`${source.id}`)).map((lump) => (
                  <>
                    <li key={lump.id}>
                      <Button
                        onClick={linkHandler(lump.id, lump.link)}
                      ><strong>{lump.title}</strong>&nbsp;
                        {" - " + timeSince(lump.created_at)}
                      </Button>{" "}
                    </li>
                    <p>{lump.description}</p>
                  </>
                ))}
              </ul>
            </Collapsible>
          ))}
      </>
    );
  };

  const todaysDate = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fullDate = `${day}.${month}.${year}.`;
    if (DEBUG === 1) console.log("FullDate", fullDate);
    return fullDate;
  };

  const showSignup = () => {
    return <div>This is protected area, you need to be logged in to use.</div>;
  };

  return (
    <>
      <center>
        <h1 className="summary-title">Summary Report for {todaysDate}</h1>
      </center>
      <Grid item container xs={12}>
        <div className="summary-container">
          {props.isLoggedin && showSummary()}
          {!props.isLoggedin && showSignup()}
        </div>
      </Grid>
    </>
  );
};

export default Summary;
