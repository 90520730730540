
export const initialState = {
  user: [],
  lumps: [],
  sources: [],
  comments: [],
  logintime: "",
  lumppage: 0,
  teamdata: [],
  lastupdated: "",
  notifications: []
}

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_LUMPS: "SET_LUMPS",
  SET_SOURCES: "SET_SOURCES",
  SET_LOGIN_TIME: "SET_LOGIN_TIME",
  SET_COMMENTS: "SET_COMMENTS",
  LOGOUT_USER: "LOGOUT_USER",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",
  ADD_TEAM: "ADD_TEAM",
  REMOVE_TEAM: "REMOVE_TEAM",
  ADD_TEAM_DATA: "ADD_TEAM_DATA",
  SET_LAST_UPDATED: "SET_LAST_UPDATED",
  ADD_NOTIFICATIONS: "ADD_NOTIFICATIONS",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  ADD_COMMENTS: "ADD_COMMENTS"

}

const reducer = (state, action) => {
  //console.log(action)
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      }
    case actionTypes.SET_LUMPS:
      return {
        ...state,
        lumps: action.lumps,
      }
    case actionTypes.SET_SOURCES:
      return {
        ...state,
        sources: action.sources,
      }
    case actionTypes.SET_LOGIN_TIME:
      return {
        ...state,
        logintime: action.logintime,
      }
    case actionTypes.SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      }
    case actionTypes.LOGOUT_USER:
      return {
        user: [],
        lumps: [],
        sources: [],
        comments: [],
        logintime: ""
      }
    case actionTypes.SET_PAGE_NUMBER:
      return {
        ...state,
        lumppage: action.pagenumber,
      }

    case actionTypes.ADD_TEAM:
      let newLumps = [...state.lumps, ...action.team]
      let uniqueLumps = [...new Set(newLumps)]
      //console.log("Unique lumps", uniqueLumps)
      return {
        ...state,
        lumps: uniqueLumps,
      }
      case actionTypes.ADD_COMMENTS:
        let newComments = [...state.comments, ...action.comments]
        let uniqueComments = [...new Set(newComments)]
        //console.log("Unique lumps", uniqueLumps)
        return {
          ...state,
          comments: uniqueComments,
        }
    case actionTypes.SET_LAST_UPDATED:
      return {
        ...state,
        lastupdated: action.lastupdated,
      }
    case actionTypes.ADD_NOTIFICATIONS:
      let notifications = [...state.notifications, ...action.notifications]
      let uniqueNotifications = [...new Set(notifications)]

      return {
        ...state,
        notifications: uniqueNotifications,
      }
    case actionTypes.REMOVE_NOTIFICATION:
      const index = state.notifications.findIndex(
        (notificationItem) => notificationItem.id === action.id
      );
      let newNotifications = [...state.notifications];

      if (index >= 0) {
        newNotifications.splice(index, 1);

      } else {
        console.warn(
          `Cant remove notification (id: ${action.id}) as its not in basket!`
        )
      }
      return {
        ...state,
        notifications: newNotifications,
      }



    default:
      return state;
  }
}

export default reducer;