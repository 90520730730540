import { Box, Button, TextField, Typography, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TeamUpdate.css";
import Autocomplete from "@mui/material/Autocomplete";
import teamdata from "../Functions/TeamData.json";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import Cookies from "universal-cookie";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InfoModal from "../InfoModal/InfoModal";
import { useStateValue } from "../../StateProvider"

const client = axios.create({
  baseURL: "https://chunkyapi.com/api/v1",
});

const TeamUpdate = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);  
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [message,setMessage] = useState("")
  const [title,setTitle] = useState("")
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [team, setTeam] = useState("");
  const [teamname, setTeamName] = useState("");
  const [sources, setSources] = useState([]);
  const [state, dispatch] = useStateValue();
  const DEBUG = props.DEBUG

  const [userState, setUserState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    username: "",
    mobile: "",
    firstname: "",
    lastname: "",
    slogan: "",
    bio: "",
    registrationErrors: "",
    team: "",
    displayname: "",
    privacy: "",
    image: "",
    filename: "",
    imageid: ""
  });

  if (DEBUG === 1) console.log("Team Update state", state)
  
  const teamdataProps = {
    options: teamdata,
    getOptionLabel: (option) =>
      option.attributes.first_name + " " + option.attributes.last_name,
  };

  const getSources = () => {
    if (sources.length == 1)
    {
      return sources
    }
   else
   {
    return sources.sort(function(a, b){
      if(a.username < b.username) { return -1; }
      if(a.username > b.username) { return 1; }
      return 0;
  });
   }
       
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  let token;
  token = cookies.get("token");
  
  if (DEBUG === 1) console.log("Token: ", token);

  

  const removeTeam = (team) => {
    if (DEBUG === 1) console.log("Remove Team", team);
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/removesource/${token}/${team}`;

    axios
      .get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'crossdomain': true,
          'Origin': 'chunkysports.com'
        }
      })
      .then((response) => {
        if (response.data.successful) {
          setSources(response.data.sources);
          props.updateSources(response.data.sources)
        }

        if (DEBUG === 1) console.log("Response", response);
      })
      .catch((response) => console.log(response));
  };

  const addTeam = (team) => {
    if (team !== "") {
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/addsource/${token}/${team}`;

      axios
        .get(url)
        .then((response) => {
          if (response.data.successful) {
            if (DEBUG === 1) console.log("Add Team", response)
            setSources(response.data.sources);
            props.updateSources(response.data.sources)
            dispatch({
              type: 'ADD_TEAM',
              team: response.data.lumps
        
            })
           
          }
          else
          {
            setMessage(`${teamname} already exists in team list.`)
            setTitle(`${teamname} not added!`)
            setOpen(true)
            if (DEBUG === 1) console.log("Add Team ELSE",message)
          }

          if (DEBUG === 1) console.log("Response", response);
        })
        .catch((response) => console.log(response));
    }
  };

  if (DEBUG === 1) console.log("Sources", sources);
  if (DEBUG === 1) console.log("Sources", state.sources)

  function handleTeamChange(event, value) {
    if (typeof value === "object" && value !== null) {
      setTeam(value.attributes.username);
      setTeamName(`${value.attributes.first_name} ${value.attributes.last_name}`)
      if (DEBUG === 1) console.log("Team Value", value.attributes.username);
    }
  }

  const handleClose = () => {
    navigate("/")
  }

  const handleInfoClose = () => {
    setOpen(false)
  }
  

  const getSourceMap = () => {
    return getSources().map((source) => (
      <Box
        key={source.id}
        sx={{
          ":hover": { background: "#fff" },
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div>
          <img
            className="w-[30px] bg-cover rounded-full"
            src={source.logo30}
            alt={
              source.first_name + " " + source.last_name
            }
          />
        </div>
        <div>
          <Typography
            sx={{
              fontSize: 12,
              ml: 1,
              fontWeight: "bold",
              color: "#000",
            }}
          >
            {source.first_name + " " + source.last_name}
          </Typography>
        </div>

        <div>
          <Tooltip
            title={`Remove ${source.first_name} ${source.last_name}`}
            placement="right"
          >
            <IconButton
              onClick={() => {
                removeTeam(`${source.username}`);
              }}
            >
              <ClearIcon color="error" />
            </IconButton>
           
          </Tooltip>
        </div>
      </Box>
    ));
  };

  if (DEBUG === 1) console.log("Sources", sources);
  useEffect(() => {

    if (state.user)
    {
      setSources(state.sources)
      setLoaded(true)
    }
    else
    {
      navigate("/login")
    }
    // async function getSources() {
    //   console.log("Token in getsources", token);
    //   const response = await client.get(`/getsources/${token}`);
    //   setSources(response.data.data);
    // }

    // getSources();
   
  }, [sources.length]);

  return (
    <div>
      <Box
        sx={{
          maxWidth: "600px",
          width: { xs: "100%", sm: "460px" },
          margin: "auto",
          width: "100%",
          background: "#fff",
          p: { xs: 2, sm: 3 },
          borderRadius: "12px",
          boxShadow: 24,
        }}
      >

          <InfoModal
                handleClose={handleInfoClose}
                open={open}
                message={message}
                title={title}
                        
              />
        <form
          onSubmit={handleSubmit}
          action=" "
          method="post"
          id="contact_form"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Chunky Sports Team Managament
          </Typography>
          <br />

          <div className="teamupdate_addcontainer">
            <div className="teamupdate_autocomplete">
              <label style={{ fontWeight: "bold" }}>Add Team</label>
              <Tooltip title={"Type first few characters of city or mascot"} placement="top">
                <Autocomplete
                  sx={{
                    color: "success.main",
                  }}
                  {...teamdataProps}
                  id="open-on-focus"
                  openOnFocus
                  onChange={handleTeamChange}
                  renderInput={(params) => (
                    <TextField
                      type="text"
                      fullWidth
                      name="team"
                      label="Search Team to Add"
                      value={userState.team}
                      sx={{ mb: 2, mt: 1 }}
                      {...params}
                    />
                  )}
                />
              </Tooltip>
            </div>
            <div className="teamupdate_addbutton">
              <Tooltip title={"Click to Add Team"} placement="right">
                <IconButton size="large"
                  onClick={() => {
                    addTeam(team);
                  }}
                > 
                  <AddCircleOutlineIcon color="primary" size="large">
                    Add 
                  </AddCircleOutlineIcon>
                  Add
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {loaded && (
            <div>
              <label style={{ fontWeight: "bold" }}>Remove Team</label>
              {getSourceMap()}
            </div>
          )}

          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            sx={{
              py: 1.5,
              mt: 2,
              background: "#1877f2 !important",
              color: "#fff",
            }}
          >
            Close
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default TeamUpdate;
