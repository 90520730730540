import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import Login from "./Login/Login";
import Middle from "./Middle/Middle";
import Signup from "./Signup/Signup";
import Team from "./Team/Team";
import Home from "./Home/Home";
import Urlform from "./Urlform/Urlform";
import "./App.css";
import PageLayout from "./PageLayout/PageLayout";
import TeamLinks from "./TeamLinks/TeamLinks";
import { Box } from "@mui/material";
import Summary from "./Summary/Summary";
import TeamUpdate from "./TeamUpdate/TeamUpdate";
import LumpItem from "./LumpItem/LumpItem"
import Terms from "./Terms/Terms"
import Privacy from "./Privacy/Privacy";
import UserProfile from "./UserProfile/UserProfile";
import Colors from "./Colors/Colors";
import TeamSummary from "./TeamSummary/TeamSummary";
import { Resize } from "./Resize/Resize";
import { useStateValue } from "../StateProvider"




const App = () => {
  const DEBUG = 2
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const cookies = new Cookies();
  const navigate = useNavigate()
  const [token, setToken] = useState("");
  const [userLoginData, setUserLoginData] = useState([]);
  const [state, dispatch] = useStateValue();

  const [userState, setUserState] = useState({
    isLoggedin: false,
    user: {},
    lumps: {},
    sources: {},
  });

  const setUser = (response) => {
    if (DEBUG === 2) console.log("Response setUser", response);
    dispatch({
      type: 'SET_USER',
      user: response.user

    })
    dispatch({
      type: 'SET_SOURCES',
      sources: response.sources

    })
    dispatch({
      type: 'SET_LOGIN_TIME',
      logintime: response.logintime

    })
    dispatch({
      type: 'SET_LAST_UPDATED',
      lastupdated: response.logintime
    })
    dispatch({
      type: 'SET_LUMPS',
      lumps: response.lumps
    })
    dispatch({
      type: 'SET_COMMENTS',
      comments: response.comments
    })

  }


  if (DEBUG === 1) console.log("API Server", process.env.REACT_APP_API_SERVER)


  const checkLoginStatus = () => {

    const temp = cookies.get("token");

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/loggedin/${temp}`;



    axios
      .get(url)
      .then((response) => {
        if (DEBUG === 1)
        {
          console.log("Checking Login Status", response);
        }
        
        if (response.data.isLoggedin) {
          setUserLoginData(response.data.sources);
          // console.log(response.data)
          cookies.set('token', response.data.user.authentication_token, { path: '/', maxAge: 7 * 24 * 60 * 60 }); // set the 'mytoken' cookie for 7 days
          localStorage.setItem("mytoken", response.data.user.authentication_token);
          localStorage.setItem("last_updated", response.data.logintime)
        }
        else {
          setUserLoginData([]);
        }


        if (response.data.isLoggedin && userState.isLoggedin === false) {
          setUserState({
            isLoggedin: true,
            user: response.data.user,
            lumps: response.data.lumps,
            sources: response.data.sources.data,
          });
          setUser(response.data)
          console.log(response.data.user)
          if (DEBUG === 1)
          {
            console.log("Sources in Loggedin", response.data.sources)
            console.log("State", state)
          }
          

        } else if (!response.data.isLoggedin && userState.isLoggedin) {
          setUserState({
            isLoggedin: false,
            user: {},
            lumps: response.data.lumps,
          });
        }
      })
      .catch((response) => console.log("Failed response", response));
  };

  // console.log('userLoginData', userLoginData)

  const handleSuccessfulAuth = (data) => {
    //Todo update parent component
    if (DEBUG === 2) console.log("Handle Successful Auth", data);

    handleLogin(data);
    setUser(data)


  };

  const handleSuccessfulGoogleAuth = (data) => {
    //Todo update parent component
    if (DEBUG === 1) console.log("Handle Successful Login", data);
    const email = { email: data.email };
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/isemailexists`;

    if (DEBUG === 1) console.log("Email Address in Google", data.email);
    axios
      .post(url, email)
      .then((response) => {
        if (DEBUG === 1) console.log("Successful response", response);

        if (response.data.successful) {
          const userData = {
            user: {
              email: data.email,
              password: "Password99!!",
              password_confirmation: "Password99!!",
              username: data.displayName,
              displayname: data.displayName,
              mobile: "",
              firstname: "",
              lastname: "",
              slogan: "",
              bio: "",
              image: data.photoURL,
              registrationErrors: "",
              team: "",
            }
          }


        } else {

        }
      })
      .catch((response) => console.log("Failed response", response));




    handleLogin(data);

  };



  const updateSources = (data) => {
    dispatch({
      type: 'SET_SOURCES',
      sources: data

    })
  }

  const handleLogoutClick = () => {
    // const url = "/logout";
    const temp = cookies.get("token");
    if (DEBUG === 1) console.log('temp', temp);
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/logout/${temp}`;

    axios.get(url)
      .then((response) => {
        if (DEBUG === 2) console.log("Successful logout", response)
        handleLogout();
        navigate('/')
      })
      .catch((response) =>
     
        console.log("Error Failed Logout response", response)
      
      );
  };

  const handleLogin = (data) => {
    if (DEBUG === 2) console.log("Handle Login", data)

    setUserState({
      isLoggedin: true,
      user: data.user,
      lumps: data.lumps,
      sources: data.sources.data
    });
    if (DEBUG === 1) console.log("Before Cookie", data)
    cookies.set("token", data.user.authentication_token, {
      path: "/",
      domain: "chunkysports.com",
      sameSite: "lax",
    });
    cookies.set("token", data.user.authentication_token, {
      path: "/",
      domain: "chunkyreport.com",
      sameSite: "lax",
    });
    cookies.set("token", data.user.authentication_token, {
      path: "/",
      domain: "chunkydata.com",
      sameSite: "lax",
    });
    cookies.set("token", data.user.authentication_token, {
      path: "/",
      domain: "localhost",
      sameSite: "lax",
    });

  };

  const handleLogout = () => {
    //console.log("Handle Login")

    setUserState({
      isLoggedin: false,
      user: {},
      lumps: {},
      sources: {}
    });

    dispatch({
      type: 'LOGOUT_USER'


    })

  };


  useEffect(() => {
    checkLoginStatus();

  }, [state.user]);

  return (
    <Box>
      <PageLayout state={userState} userLoginData={userLoginData} handleLogoutClick={handleLogoutClick} DEBUG={DEBUG}>
        <Routes>
          <Route
            path="/"

            element={
              <Home
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                setUser={setUser}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/home"
            element={
              <Home
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                setUser={setUser}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <Terms
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/colors"
            element={
              <Colors
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/userprofile"
            element={
              <UserProfile
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/privacy"
            element={
              <Privacy
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                isLoggedin={userState.isLoggedin}
                handleSuccessfulAuth={handleSuccessfulAuth}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/summary"
            element={
              <Summary
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                setUser={setUser}
                DEBUG={DEBUG}
              />
            }
          />
          <Route
            path="/resize"
            element={
              <Resize
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                DEBUG={DEBUG}
              />
            }
          />

          <Route
            path="/teamupdate"
            element={
              <TeamUpdate
                sources={userState.sources}
                lumps={userState.lumps}
                user={userState.user}
                isLoggedin={userState.isLoggedin}
                updateSources={updateSources}
                DEBUG={DEBUG}              
              />
            }
          />
          <Route
            path="/login"
            element={<Login handleSuccessfulAuth={handleSuccessfulAuth}
             handleSuccessfulGoogleAuth={handleSuccessfulGoogleAuth} 
             DEBUG={DEBUG}
             />}
          />
          <Route path="/urls" element={<Urlform />} />
          <Route
            path="/source/:id"
            element={<Middle user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/lumps/:id"
            element={<LumpItem user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/nba/:id"
            element={<Team user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams"
            element={<TeamLinks user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/nfl"
            element={<TeamLinks user={userState.user} isLoggedin={userState.isLoggedin} league="NFL" DEBUG={DEBUG} />}
          />
          <Route
            path="/teams/nba"
            element={<TeamLinks user={userState.user} isLoggedin={userState.isLoggedin} league="NBA" DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/mlb"
            element={<TeamLinks user={userState.user} isLoggedin={userState.isLoggedin} league="MLB" DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/nhl"
            element={<TeamLinks user={userState.user} isLoggedin={userState.isLoggedin} league="NHL" DEBUG={DEBUG}/>}
          />

          <Route
            path="/teams/nfl/:id"
            element={<Team user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/nhl/:id"
            element={<Team user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teams/mlb/:id"
            element={<Team user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />
          <Route
            path="/teamsummary/:id"
            element={<TeamSummary user={userState.user} isLoggedin={userState.isLoggedin} DEBUG={DEBUG}/>}
          />

        </Routes>
      </PageLayout>
    </Box>
  );
};

export default App;
