import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Lump.css";
import Lumpitems from "../Lumpitems/Lumpitems";
import { Grid } from "@mui/material";
import { linkHandler, timeSince } from "../Functions/Functions";
import { useStateValue } from "../../StateProvider";
import { useAuth } from "../../context/AuthContext";


const Lump = (props) => {
  const [state, dispatch] = useStateValue();
  const cookies = new Cookies();
  const [source, setSource] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [update, setUpdate] = useState("");
  const [lastupdated, setLastUpdated] = useState("");
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [notifications, setNotifications] = useState(0);
  const [updatedData, setUpdatedData,] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const { currentUser, firebaseLogOut } = useAuth();
  const [comments, setComments] = useState([]);

  const DEBUG = props.DEBUG;

  const getData = useCallback(
    (pages) => {
      let temp = cookies.get("token");
      if (temp == null) {
        temp = "";
      }
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/pages`;
      const team = "";
      axios
        .post(url, {
          id: temp,
          page: page,
          pages: pages,
          team: team,
          lastupdated: state.lastupdated,
        })
        .then((response) => {
          setSource([...source, ...response.data.lumps]);
          setPage(response.data.currentpage);
          dispatch({
            type: 'SET_LAST_UPDATED',
            lastupdated: response.lastupdated
          })
          setLastUpdated(response.data.lastupdated);
          if (DEBUG === 1) {
            console.log("Timeout data", response);
            console.log("Page", page);
            console.log("Source Length", source.length);
          }
        })
        .catch((response) => console.log(response));
    },
    [source, page, lastupdated, DEBUG]
  );



  useEffect(() => {
    let temp = cookies.get("token");
    if (temp == null) {
      temp = "";
    }
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/pages`;
    if (state.lumps.length == 0 && state.sources.length == 0) {
      axios
        .post(url, {
          id: temp,
          page: page,
          pages: 1,
          team: "",
          lastupdated: "",
        })
        .then((response) => {
          console.log(response);
          setSource(response.data.lumps);
          setLoaded(true);
          setLastUpdated(response.data.lastupdated);
          setPage(response.data.currentpage);
          props.setUser(response.data);
          // console.log(typeof(response.data.lumps))
          setData([...response.data.lumps])
        })
        .catch((response) => console.log(response));
    } else {
      setData([state.lumps])
      setSource(state.lumps);
      setLoaded(true);
    }
  }, []);

  // console.log(data[0])

  const url = `${process.env.REACT_APP_API_SERVER}api/v1/getdata`

  if (DEBUG === 1) console.log("URL", url);
  if (DEBUG === 1) console.log("Last Updated", state.lastupdated);
  if (DEBUG === 2) console.log("State", state);

  const testData = () => {
    const requestAddress = `${process.env.REACT_APP_API_SERVER}api/v1/getdata`
    axios
      .post(requestAddress, {
        id: state.user.authentication_token,
        lastupdated: state.lastupdated,
        verb: "update",
        page: "1",
        pages: "3"
      })
      .then((data) => {
        console.log("Fetch Data TEST AXIOS", data)
      })
      .catch((data) => console.log(data));

  }

  const fetchData = async () => {
    const requestAddress = `${process.env.REACT_APP_API_SERVER}api/v1/getdata`
    // const token = localStorage.getItem('mytoken');
    let token = cookies.get("token");
    if (token === null) {
      token = "";
    }

    console.log("Token:", token)

    let lastupdated = localStorage.getItem("last_updated")
    console.log("Last Updated Date: ", lastupdated)
   
      lastupdated = localStorage.getItem("last_updated")


      console.log("Last Updated Date: ", lastupdated)
      //let currentdate = new Date(); 
      //let current = currentdate.getFullYear() +"-"+(currentdate.getMonth()+1)+"-"+("0" + currentdate.getDate()).slice(-2)+"T"+currentdate.getHours()+":"+currentdate.getMinutes() + ":"+currentdate.getSeconds() +"."+currentdate.getMilliseconds()+"-"+(currentdate.getTimezoneOffset()/60)+":00";
      //console.log("Current", current)



      const response = await axios.post(requestAddress, {
        id: token,
        lastupdated: lastupdated,
        verb: "update",
        page: "1",
        pages: "3"
      });

      if (DEBUG === 1) console.log(response.data.lastupdated)

      await dispatch({
        type: 'SET_LAST_UPDATED',
        lastupdated: response?.data?.lastupdated
      })
      localStorage.setItem("last_updated", response.data.lastupdated)

      if (DEBUG === 2) console.log("Fetch Data", response);

      const newcomments = response?.data?.comments;
      if (newcomments && newcomments.length > 0) {
        // Do something with the comments array
        await dispatch({
          type: 'ADD_COMMENTS',
          comments: response?.data?.comments
        })
      }

      const apiArray = response?.data?.lumps;
      if (apiArray && apiArray.length > 0) {

        await dispatch({
          type: 'ADD_NOTIFICATIONS',
          notifications: apiArray
        })
      }





      // Get stored data from local storage
      const storedArray = JSON.parse(localStorage.getItem('storedArray')) || [];

      let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
      const itemsNotMatched = JSON.parse(localStorage.getItem('itemsNotMatched')) || [];
      // console.log(storedArray.length)

      // Check and compare the items of apiArray and storedArray
      if (storedArray.length > 0) {
        for (let i = 0; i < apiArray.length; i++) {
          const foundItem = storedArray.filter((item) => item.title === apiArray[i].title);
          console.log(foundItem)
          if (foundItem.length == 0) {
            notificationCount++;
            itemsNotMatched.push(apiArray[i]);
          }
        }
      }

      //Add to Notifications State



      // Update stored data in local storage
      localStorage.setItem('storedArray', JSON.stringify(apiArray));
      localStorage.setItem('notificationCount', notificationCount);
      localStorage.setItem('itemsNotMatched', JSON.stringify(itemsNotMatched));
      // Set notification count in state
      setNotificationCount(notificationCount);

   
  };

  useEffect(() => {
    let intervalId = null;

    if (state.lumps.length === 0 && state.sources.length === 0) {
      fetchData();
    }

    intervalId = setInterval(() => {
      fetchData();
      // console.log(notificationCount)
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);



  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     let temp = cookies.get("token");
  //     if (temp == null) {
  //       temp = "";
  //     }
  //     const url = `${process.env.REACT_APP_API_SERVER}api/v1/pages`;
  //     if (state.lumps.length == 0 && state.sources.length == 0) {
  //       axios
  //         .post(url, {
  //           id: temp,
  //           page: page,
  //           pages: 1,
  //           team: "",
  //           lastupdated: "",
  //         })
  //       .then((newData) => {
  //         let updateCount = 0;
  //         // console.log(newData.data.lumps)
  //         newData?.data?.lumps.forEach(newItem => {
  //           // console.log(newItem)
  //           const existingItem = data.filter(item => item.title === newItem.title);
  //           if (!existingItem || JSON.stringify(existingItem) !== JSON.stringify(newItem)) {
  //             updateCount += 1;
  //             setUpdatedData(...updatedData, newItem);
  //           }
  //         });
  //         if (updateCount > 0) {
  //           setData(newData);
  //           setNotifications(updateCount);
  //           console.log(updatedData)
  //         }
  //       });
  //     }
  //     console.log(notifications)
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, [data, notifications]);


  const clickHandler = useCallback(
    (id) => {
      const url = `/api/v1/ban/${id}`;
      const pathname = window.location.pathname;
      const lastitem = pathname.substring(pathname.lastIndexOf("/") + 1);
      axios
        .post(url, { id: id, lastitem: lastitem })
        .then((response) => {
          setUpdate(response.data);
          setSource(source.filter((item) => item._id !== id));
        })
        .catch((response) => console.log(response));
    },
    [source]
  );

  let server;

  server = "https://dm1r1mwnqdyan.cloudfront.net";

  return (
    <>
      {loaded ? (
        <>
          <div className="lump-header">
            <p>Last Updated: {timeSince(lastupdated)}</p>

            {/*<Button variant="contained" onClick={() => testData()}>
              Load More
      </Button>*/}

          </div>
          <div id="scrollable" style={{ height: "95%", overflow: "hidden" }}>
            {source.map((post) => (
              <Grid item xs={12} key={post.id} sx={{ mt: 2 }}>
                <Lumpitems
                  server={server}
                  username={post.username}
                  name={post.name}
                  category={post.category}
                  image={post.image}
                  title={post.title}
                  link={post.link}
                  created_at={post.created_at}
                  description={post.description}
                  youtube={post.youtube}
                  linkHandler={linkHandler}
                  timeSince={timeSince}
                  user={state.user}
                  id={post.id}
                  lumpcomments={post.comments}
                />
              </Grid>
            ))}


          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default React.memo(Lump);
