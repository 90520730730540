import React, { useCallback } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import {
  FaThumbsUp,
  FaHeart,
  FaSmile,
  FaAngry,
  FaRegGrinAlt,
  FaVideo,
  FaRegImages,
} from "react-icons/fa";
import {
  Avatar,
  Box,
  Divider,
  Typography,
  Link as MuiLink,
  Grid,
} from "@mui/material";
import "./Lumpitems.css";
import Comments from "../Comments/Comments";

const Lumpitems = React.memo(
  ({
    id,
    category,
    username,
    first,
    last,
    server,
    DEBUG,
    key,
    timeSince,
    created_at,
    image,
    link,
    youtube,
    name,
    title,
    description,
    user,
    linkHandler,
    lumpcomments
  }) => {
    const datestring = timeSince(created_at);

    const toggleComments = useCallback(
      (commentID) => (event) => {
        event.preventDefault();
        if (DEBUG === 1) console.log("CommentID", commentID);
      },
      [DEBUG]
    );

    const getUserId = () => {
      return user ? user.id : 9999;
    };


    const [comments, setComments] = React.useState([]);

    const handleSubmitComment = (comment) => {
      setComments([...comments, comment]);
    };

    return (
      <Box
        key={id}
        sx={{
          bgcolor: "#e2e8f0",
          p: { xs: 2, sm: 3 },
          boxShadow: 3,
          borderRadius: "2px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 2,
          }}
        >
          <Link
            to={`/teams/${category}/${username}`}
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            <Avatar
              src={server + "/logos50/" + username + ".png"}
              alt={first + " " + last}
              sx={{ width: "50px", borderRadius: "50%", ml: { xs: 0, sm: 3 } }}
            />

            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", color: "#000" }}
            >
              {`${name} -  ${category}`}
            </Typography>
          </Link>
        </Box>
        <Divider />

        <Box
          sx={{
            my: { xs: 2, sm: 1.5 },
            alignItems: "center",
            display: "flex",
          }}
        >
          <Avatar
            src={image}
            alt={title}
            sx={{
              width: { xs: 40, sm: "50px" },
              height: { xs: 40, sm: "50px" },
              mr: 2,
            }}
          />
          <div className="flex flex-col items-start justify-start">
            <a
              href={"#"}
              target="_blank"
              className="font-bold"
              rel="noreferrer"
              onClick={linkHandler(id, link, getUserId())}
            >
              {title}
            </a>
            <p className="text-sm text-slate-500">{datestring}</p>
          </div>
        </Box>
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "normal",
              color: "#000",
              my: { xs: 2, sm: 1.5 },
            }}
          >
            {description}
          </Typography>

          {!youtube &&
            <LazyLoad>
              <a
                href={"#"}
                target="_blank"
                className="font-bold"
                rel="noreferrer"
                onClick={linkHandler(id, link, getUserId)}
              >
                <Box
                  component="img"
                  src={image}
                  alt={title}
                  sx={{
                    width: "100%",
                    height: "100%",
                    "&:hover": { boxShadow: 3, borderRadius: "7px" },
                  }}
                />
              </a>
            </LazyLoad>}
          {youtube &&
            <LazyLoad>
              <a
                href={"#"}
                target="_blank"
                className="font-bold"
                rel="noreferrer"
                onClick={linkHandler(id, link, getUserId)}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    "&:hover": { boxShadow: 3, borderRadius: "7px" },
                  }}
                >

                  <div className="video-container">
                    <ReactPlayer
                      url={link}
                      controls="true"

                    />

                  </div>
                </Box>
              </a>
            </LazyLoad>}
        </Box>

        {/*  Rating Icons */}
        <Box>
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                maxWidth: "140px !important",
                width: "100%",
                alignItems: "center",
                my: { xs: 1, sm: 0 },
              }}
            >
              <FaThumbsUp size={20} color="blue" style={{ cursor: "pointer" }} />
              <FaHeart size={20} color="red" style={{ cursor: "pointer" }} />
              <FaSmile size={20} style={{ cursor: "pointer" }} />
              <FaAngry size={20} style={{ cursor: "pointer" }} />
            </Grid>
            {/* <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              my: { xs: 1, sm: 0 },
            }}
          >
            <p className="font-bold cursor-pointer" >
              <Button onClick={toggleComments(key)}>
                {Math.floor(Math.random() * 51)} Comments
              </Button>

            </p>
          </Grid> */}
          </Grid>
        </Box>

        <hr className="m-0" />
        {/* <div className="flex items-center justify-between font-bold">
        <span className="flex items-center justify-start gap-2 cursor-pointer">
          <FaVideo className="live" />
          <span className=""> Live </span>
        </span>
        <span className="flex items-center justify-start gap-2 cursor-pointer">
          <FaRegImages className="photo" />
          <span className=""> Photo </span>
        </span>
        <span className="flex items-center justify-start gap-2 cursor-pointer">
          <FaRegGrinAlt className="feeling" />
          <span className=""> Feeling </span>
        </span>
        </div> */}
        <hr className="m-0" />
        <div id={"comments-" + key}>
          <Box>
            {/* <Comments /> */}
            <Comments postId={id} comments={comments} onCommentSubmit={handleSubmitComment}
            />
          </Box>
        </div>
      </Box>
    );
  }
);

export default Lumpitems;
