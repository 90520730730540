import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Collapsible from "react-collapsible";
import axios from "axios";
import Cookies from "universal-cookie";
import "./TeamSummary.css";
import { linkHandler, timeSince } from "../Functions/Functions";
import Button from "@mui/material/Button";
import TeamLogo from "../TeamLogo/TeamLogo"
import { useParams } from "react-router-dom";


const TeamSummary = (props) => {
  const params = useParams();
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const [lumps, setLumps] = useState([]);
  const [sources,setSources] = useState([]);
  const [source,setSource] = useState([]);
  const DEBUG = props.DEBUG

  let server
  
  server = "https://dm1r1mwnqdyan.cloudfront.net"
  const cookies = new Cookies();
 
  const [loaded, setLoaded] = useState(false);

  const getLumps = (sourceID) => {
      return lumps
      .filter((lump) => lump.source_id === sourceID)
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() -
          new Date(a.created_at).getTime()
      );
  };

  

  
  useEffect(() => {
    let temp
    temp = cookies.get("token");
   
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/nba/${params.id}`;



    axios
      .get(url,{ headers: {
        'Access-Control-Allow-Origin': '*',
        'crossdomain': true,
        'Origin': 'chunkysports.com'
      }})
      .then((response) => {
        if (DEBUG === 1) console.log("Summary Response",response)
        setLumps(response.data.lumps)
        setSource(response.data.source)
        setLoaded(true);
        //console.log(response.data.data);
      })
      .catch((response) => console.log(response));
  }, []);

  if (DEBUG === 1) console.log("Sources in Sources", sources)

  const showSummary = () => {
   
    if (DEBUG === 1) console.log("Source",source)

    return (
      <>
        {loaded &&
          
            <Collapsible
              trigger={<TeamLogo first={source.first_name} 
              last={source.last_name}
              username={source.username}
              server={server}
              logo={source.logo50}
              />            
               }
              key={source.id}
              transitionTime="50"
            >
              <ul>
                {getLumps(parseInt(`${source.id}`)).map((lump) => (
                  <>
                    <li key={lump.id}>
                      <Button
                        onClick={linkHandler(lump.id, lump.link)}
                      ><strong>{lump.title}</strong>&nbsp;
                        {" - " + timeSince(lump.created_at)}
                      </Button>{" "}
                    </li>
                    <p>{lump.description}</p>
                  </>
                ))}
              </ul>
            </Collapsible>
          }
      </>
    );
  };

  const todaysDate = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let fullDate = `${day}.${month}.${year}.`;
    if (DEBUG === 1) console.log("FullDate", fullDate);
    return fullDate;
  };

  const showSignup = () => {
    return <div>This is protected area, you need to be logged in to use.</div>;
  };

  return (
    <>
      <center>
        <h1 className="summary-title">Summary Report for {todaysDate}</h1>
      </center>
      <Grid item container xs={12}>
        <div className="summary-container">
          {showSummary()}
         
        </div>
      </Grid>
    </>
  );
};

export default TeamSummary;
