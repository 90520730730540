import React from "react";
import TeamLinks from '../TeamLinks/TeamLinks';
import { Box } from "@mui/system";

function SimpleMenu({user , isLoggedin, league}) {

  return (
    <Box>
      <div className="dropdown">
        <button className="dropbtn" style={{ height:'68px', width:'60px',border:'0.5px solid #000' }}>
          {league}
          <i className="fa fa-caret-down"></i>
        </button>
        <Box className="dropdown-content" sx={{ maxWidth:500, }}>
          <div className="dropdown-area">
        <TeamLinks user={user} isLoggedin={isLoggedin} league={league} />
        </div>
        </Box>
      </div>
    </Box>
  );
}

export default SimpleMenu;
