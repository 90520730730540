import React, { useState, useRef } from "react";
import "./Comments.css"; // import CSS file
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useStateValue } from "../../StateProvider";

const Comments = ({ comments, postId }) => {
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState("");
  const [showCommentForm, setShowCommentForm] = useState(false); // new state to show/hide comment form
  const [state, dispatch] = useStateValue();

  const [showFullReplies, setShowFullReplies] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [seeMoreId, setSeeMoreId] = useState();

  const handleCommentChange = (event) => {
    setCommentText(event.target.value);
  };

  const handleReplyChange = (event) => {
    setReplyText(event.target.value);
  };

  const handleReplyClick = (commentId) => {
    setReplyToCommentId(commentId);
  };

  const handleCancelReply = () => {
    setReplyToCommentId("");
    setReplyText("");
  };

  const openLump = (lumpid) => {

  }

  const handleSubmitComment = () => {
    const newCommentObj = {
      id: Math.floor(Math.random() * 1000), // generate unique id
      parent_id: null,
      text: commentText,
      replies: [],
    };
    // Add new comment to the list of comments
    comments?.push(newCommentObj);
    setCommentText("");
  };

  const handleSubmitReply = (parentComment) => {
    if (parentComment?.replies?.length <= 2) {
      // check if we can add more replies
      const newCommentObj = {
        id: Math.floor(Math.random() * 1000), // generate unique id
        parent_id: parentComment.id,
        text: replyText,
        replies: [],
      };
      // Add new reply to the parent comment's list of replies
      parentComment?.replies?.push(newCommentObj);
      setReplyText("");
      setReplyToCommentId("");
    }
  };

  function renderLumpComments(comment, depth = 0) {
    let lumpcomments = [];

    try {
      lumpcomments = JSON.parse(localStorage.getItem("comments"));
    } catch (e) {
      console.error("Error parsing comments from local storage: ", e);
    }

    // Do something with lumpcomments

    const myComments = state?.comments;
    const filteredComments = myComments?.filter(comment => comment.lump_id === postId);

    // console.log(myComments)
    return (
      <div>
        {filteredComments?.length > 0
          ? filteredComments?.map((lumpcomment, id) => {
            return (
              <div>
                <div
                  key={id}
                  className={`comment depth-${depth}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      class="comment-image"
                      src={lumpcomment?.image}
                      alt={""}
                      style={{ borderRadius: "50%", marginRight: "10px" }}
                    />
                    <span
                      class="comment-user"
                      style={{
                        fontWeight: "bold",
                        // fontStyle: "italic",
                        textTransform: "uppercase",
                      }}
                    >
                      {lumpcomment?.displayname}
                    </span>
                  </div>

                  {lumpcomment?.body?.length > 100 ? ( // added check for length of text
                    <div>
                      <span
                        class="comment-text"
                        style={{ marginBottom: "10px", maxHeight: "100px", overflow: "hidden" }}
                      >
                        {lumpcomment?.body?.substring(0, 100)}
                        {showFullText && seeMoreId === id ? lumpcomment?.body?.substring(40) : null} {/* added conditional rendering */}
                      </span>
                      {lumpcomment?.body?.length > 100 && (
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => { setShowFullText(!showFullText); setSeeMoreId(id) }}
                        >
                          {showFullText && seeMoreId === id ? "See less" : "See more"}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span class="comment-text" style={{ marginBottom: "10px" }}>
                      {lumpcomment?.body}
                    </span>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button
                      onClick={() => handleReplyClick(id)}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "lightblue",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      Reply
                    </button>
                    {replyToCommentId === id && (
                      <div
                        className="comment-form"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        {state.user ? (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#f5f5f5",
                                borderRadius: "10px",
                                padding: "5px 10px",
                                marginTop: "10px",
                              }}
                            >
                              <input
                                placeholder="Write reply"
                                style={{
                                  width: "100%",
                                  height: "30px",
                                  border: "none",
                                  borderRadius: "20px",
                                  paddingLeft: "15px",
                                  fontSize: "14px",
                                  backgroundColor: "#fff",
                                  boxShadow: "0px 0px 3px rgba(0,0,0,0.2)",
                                }}
                                value={replyText}
                                onChange={handleReplyChange}
                              />
                              <SendIcon
                                onMouseEnter={(e) =>
                                  (e.target.style.color = "#3b5998")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.color = "#4267B2")
                                }
                                onClick={() => handleSubmitReply(lumpcomment)}
                                style={{
                                  color: "#4267B2",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  marginLeft: "10px",
                                }}
                              />
                              <CloseIcon
                                onClick={handleCancelReply}
                                style={{
                                  color: "#999",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  marginLeft: "10px",
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <span style={{ fontStyle: "italic" }}>
                            Login to write reply
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            );
          })
          : comments.length > 0 ? null : <p>No Comments Found!</p>}
      </div>
    );
  }



  const renderComment = (comment, depth = 0) => {
    const { id, parent_id, text, replies } = comment;

    function handleSeeMoreClick() {
      setShowFullText(true);
    }

    function handleSeeLessClick() {
      setShowFullText(false);
    }


    return (
      <div>
        <div
          key={id}
          className={`comment depth-${depth}`}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <img
              class="comment-image"
              src={state.user?.image}
              alt={""}
              style={{ borderRadius: "50%", marginRight: "10px" }}
            />
            <span
              class="comment-user"
              style={{
                fontWeight: "bold",
                // fontStyle: "italic",
                textTransform: "uppercase",
              }}
            >
              {state.user?.username}
            </span>
          </div>
          {text.length > 100 ? ( // added check for length of text
            <div>
              <span
                class="comment-text"
                style={{ marginBottom: "10px", maxHeight: "100px", overflow: "hidden" }}
              >
                {text.substring(0, 100)}
                {showFullText ? text.substring(100) : null} {/* added conditional rendering */}
              </span>
              {text.length > 100 && (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "See less" : "See more"}
                </span>
              )}
            </div>
          ) : (
            <span class="comment-text" style={{ marginBottom: "10px" }}>
              {text}
            </span>
          )}


          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={() => { handleReplyClick(id); setShowFullReplies(true) }}

              style={{
                marginRight: "10px",
                backgroundColor: "lightblue",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              Reply
            </button>



            {replyToCommentId === id && (
              <div
                className="comment-form"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {state.user ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      marginTop: "10px",
                    }}
                  >
                    <input
                      placeholder="Write reply"
                      style={{
                        width: "100%",
                        height: "30px",
                        border: "none",
                        borderRadius: "20px",
                        paddingLeft: "15px",
                        fontSize: "14px",
                        backgroundColor: "#fff",
                        boxShadow: "0px 0px 3px rgba(0,0,0,0.2)",
                      }}
                      value={replyText}
                      onChange={handleReplyChange}
                    />
                    <SendIcon
                      onMouseEnter={(e) => (e.target.style.color = "#3b5998")}
                      onMouseLeave={(e) => (e.target.style.color = "#4267B2")}
                      onClick={() => handleSubmitReply(comment)}
                      style={{
                        color: "#4267B2",
                        cursor: "pointer",
                        fontSize: "18px",
                        marginLeft: "10px",
                      }}
                    />
                    <CloseIcon
                      onClick={handleCancelReply}
                      style={{
                        color: "#999",
                        cursor: "pointer",
                        fontSize: "18px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                ) : (
                  <span style={{ fontStyle: "italic" }}>
                    Login to write reply
                  </span>
                )}
              </div>
            )}
          </div>
          {depth < 2 && replies?.length > 0 && showFullReplies && (
            <div className="comment-replies" style={{ marginTop: "10px" }}>
              {replies?.map((reply) => renderComment(reply, depth + 1))}
            </div>

          )}

        </div>

      </div>
    );
  };

  return (
    <div>

      {!showCommentForm ?
        <div className="flex items-center justify-between font-bold">
          <button
            onClick={() => setShowCommentForm((prev) => !prev)}
            style={{
              backgroundColor: "#4267B2",
              color: "white",
              border: "none",
              padding: "5px 15px",
              borderRadius: "20px",
              fontSize: "13px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "background-color 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#3b5998")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#4267B2")}
          >
            <span style={{ marginRight: "10px" }}>
              <i className="far fa-comment"></i>
            </span>
            View Comments
          </button>
        </div>
        :
        <div className="flex items-center justify-between font-bold">
          <button
            onClick={() => setShowCommentForm((prev) => !prev)}
            style={{
              backgroundColor: "#4267B2",
              color: "white",
              border: "none",
              padding: "5px 15px",
              borderRadius: "20px",
              fontSize: "13px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "background-color 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#3b5998")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#4267B2")}
          >
            <span style={{ marginRight: "10px" }}>
              <i className="far fa-comment"></i>
            </span>
            Hide Comments
          </button>
        </div>
      }

      <button
        onClick={() => openLump((prev) => !prev)}
        style={{
          backgroundColor: "#4267B2",
          color: "white",
          border: "none",
          padding: "5px 15px",
          borderRadius: "20px",
          fontSize: "13px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease-in-out",
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#3b5998")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#4267B2")}
      >
        <span style={{ marginRight: "10px" }}>
          <i className="far fa-comment"></i>
        </span>
        Open in New Window
      </button>

      <button
        onClick={() => setShowCommentForm((prev) => !prev)}
        style={{
          backgroundColor: "#4267B2",
          color: "white",
          border: "none",
          padding: "5px 15px",
          borderRadius: "20px",
          fontSize: "13px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s ease-in-out",
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#3b5998")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#4267B2")}
      >
        <span style={{ marginRight: "10px" }}>
          <i className="far fa-comment"></i>
        </span>
        Save
      </button>

      {showCommentForm && (
        <div style={{ marginTop: "20px" }}>
          {comments?.map((comment) => {
            if (!comment?.parent_id) {
              return renderComment(comment);
            }
            return null;
          })}

          {renderLumpComments()}
          {state.user ? (
            <div style={{ marginTop: "20px", marginRight: "1rem" }}>
              <input
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "20px",
                  padding: "10px 15px",
                  fontSize: "16px",
                  maxWidth: "600px",
                }}
                placeholder="Write a comment..."
                value={commentText}
                onChange={handleCommentChange}
              />
              <button
                onClick={handleSubmitComment}
                style={{
                  backgroundColor: "#4267B2",
                  color: "white",
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "20px",
                  fontSize: "14px",
                  cursor: "pointer",
                  marginTop: "10px",
                  marginLeft: "1rem",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#3b5998")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#4267B2")
                }
              >
                Post
              </button>
              <br />
            </div>
          ) : (
            <div style={{ marginTop: "20px", color: "#666" }}>
              Login to write comment
            </div>

          )}
        </div>
      )}
    </div>
  );
};

export default Comments;
