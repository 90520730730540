import React from 'react'
import { Box } from "@mui/system";
import { Grid } from "@mui/material";

const Privacy = () => {
  return (
    <Box>
   <div className='terms-header'>
  <h1>Privacy Policy</h1>
  </div>
 
<p>Last updated: June 10, 2022</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Free Privacy Policy Generator</a>.</p>
<h1>Interpretation and Definitions</h1>
<h2>Interpretation</h2>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h2>Definitions</h2>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
</li>
<li>
<p><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Chunky Sports</p>
</li>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Chunky Data LLC, 11407 Horse Soldier Place; Beltsville,  Maryland.</p>
</li>
<li>
<p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
</li>
<li>
<p><strong>Country</strong> refers to: Maryland,  United States</p>
</li>
<li>
<p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Application or the Website or both.</p>
</li>
<li>
<p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><strong>Website</strong> refers to Chunky Sports, accessible from <a href="https://chunkyapi.com" rel="external nofollow noopener" target="_blank">https://chunkyapi.com</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<br/>
  <div className='terms-header'>
  <h2>Table of Conents</h2>
  <a href="#one"><h2>1. Collecting and Using Your Personal Data</h2></a>
  <a href="#two"><h2>2. Use of Information</h2></a>
  <a href="#three"><h2>3. Cookies</h2></a>
  <a href="#four"><h2>4. Advertising</h2></a>
  <a href="#five"><h2>5. Social Networking</h2></a>
  <a href="#six"><h2>6. Links to Other Websites</h2></a>
  <a href="#seven"><h2>7. Data Security and Data Integrity</h2></a>
  <a href="#eight"><h2>8. Data Retention</h2></a>
  <a href="#nine"><h2>9. Children's Privacy</h2></a>
  <a href="#ten"><h2>10. Communications Preferences and Access</h2></a>
  <a href="#eleven"><h2>11. Changes To This Privacy Notice</h2></a>
  <a href="#twelve"><h2>12. Contact Us</h2></a>
  <a href="#thirteen"><h2>13. Youtube Privacy</h2></a>
  

  
  </div>

  <div className='terms-paragraph'> 
<a id="one"><h2>1. Collecting and Using Your Personal Data</h2></a>
<h2>Types of Data Collected</h2>
<h3>Personal Data</h3>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
<li>Email address</li>
<li>First name and last name</li>
<li>Phone number</li>
<li>Favorite team and other personal preferences</li>
<li>Photographs, if you provide a photograph of yourself to us</li>
<li>Search queries</li>
<li>Comments and other information posted in our interactive online forums</li>
<li>Correspondence, waivers or acceptances and other information that you send to us</li>


</ul>

<h2>Information We Collect Automatically</h2>

We also may collect certain information automatically when you visit or use the Services, such as stadium WiFi networks, including:
    <ul>
    <li>Your Internet Protocol (IP) address, which is the number automatically assigned to your computer whenever you access the Internet and that can sometimes be used to derive your general geographic area;</li>
    <li>Your Media Access Control (MAC) address, which is a unique identifier assigned to a network interface controller</li>
    <li>Your device type or mobile carrier;</li>
    <li>Other unique identifiers, including mobile device identification numbers; and advertising identifiers (e.g., IDFA);</li>
    <li>Your browser type and operating system;</li>
    <li>Sites and apps you visited before and after visiting, or while using, the Services;</li>
    <li>Pages you view and links you click on within the Services;</li>
    <li>Information collected through cookies, web beacons, Local Shared Objects, and other technologies, as described further below;</li>
    <li>Information about your interactions with e-mail messages, such as the links clicked on and whether the messages were received, opened, or forwarded;</li>
    <li>If you link your social media account to your Chunky Sports account we will receive information from those social media accounts in accordance with your settings on the social platform and their privacy policy;</li>
    <li>Standard Server Log Information; and</li>
    <li>Standard Network Traffic Information when you are connected to an Chunky Sports stadium's WiFi network.</li>

    </ul>
</div>

<div className='terms-paragraph'> 
<a id="two"><h2>2. Use of Information</h2></a>


We may use information that we collect through the Services for a variety of purposes, including to:
  <ul>
   <li>Provide you with the products, promotions, services, newsletters, and information you request and respond to correspondence that we receive from you;</li>
   <li>Contact you via email and otherwise about your account;</li>
   <li>Conduct analysis on your behavior in order to better serve you with offers and content that is of interest to you;</li>
   <li>With your separate consent, contact you via email and otherwise about products, services, contests, and events that we think might be of interest to you, and otherwise send you promotional material or special offers on our behalf or on behalf of our marketing partners and/or their respective affiliates and subsidiaries and other third parties;</li>
   <li>Maintain or administer the Services, perform business analyses, or for other internal purposes to improve the quality of our business, the Services, and other products and services we offer;</li>
   <li>Publish stories, comments, photos, and other information posted in our interactive online features;</li>
   <li>Process employment applications and inquiries;</li>
   <li>Customize and personalize your use of the Services, including providing you with tailored advertising;</li>
   <li>In the case of location information, to determine your market location for purposes of administering broadcast restrictions for live games, and for determining teams and offers in which you may have an interest;</li>
   <li>Protect or enforce the legal and contractual rights, safety and security of the Chunky Sports and Chunky Sports stadiums, enforce our Terms of Use and prevent fraud; and</li>
   <li>As otherwise described to you in this Policy, at the point of collection or pursuant to your consent.</li>
    </ul>
</div>
<div className='terms-paragraph'>
<a id="three"><h2>3. Cookies</h2></a>

The Services use technologies such as cookies, beacons, tags and scripts. These technologies are used for a variety of purposes, such as analyzing trends, administering the Services, analyzing how users interact with the Services, and gathering demographic information. By using the Services, you consent to our use of cookies and similar technologies.

Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our Services, but your ability to use certain features may be limited.

The Services may use Local Storage Objects (LSOs) to collect and store information. Various browsers may offer their own management tools for removing HTML5 LSOs.

We may use analytics software to allow us to better understand the use and functionality of our Services. This software may record information such as how much you use stadium WiFi networks, the sites and apps you visit while on the network and how you interact with them, how often you use an application, the events that occur within the application, aggregated usage, performance data, and where an application was downloaded from.

We participate in the Adobe Marketing Cloud Device Co-op to better understand how you use our website and apps across the various devices you use, and to deliver tailored promotions. Learn more (https://cross-device-privacy.adobe.com) about how Adobe does this.

Some web browsers may transmit "do-not-track" signals to the websites with which the user communicates. Because of differences in how web browsers incorporate and activate this feature, it is not always clear whether users intend for these signals to be transmitted, or whether they even are aware of them. Because there currently is no industry standard concerning what, if anything, websites should do when they receive such signals, we currently do not take action in response to these signals. If and when a final standard is established and accepted, we will reassess how to respond to these signals. In some cases, third parties may be able to collect information about a user's online activities over time and across different websites when he or she uses our Services.
</div>

<div className='terms-paragraph'>
<a id="four"><h2>4. Advertising</h2></a>
We partner with third party advertisers to serve and display advertising on the Services. These partners may use cookies and Web beacons to collect information about your activities across different websites and services to provide you with relevant advertising. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking here. To serve and display advertising relevant to your location, the Services may also track your device's precise geolocation by responding to beacons or inaudible signals sent to your device's microphone. If you do not want the location of your device to be tracked by the Services, please opt-out using your device settings.
</div>
<div className='terms-paragraph'>
<a id="five"><h2>5. Social Networking</h2></a>

The Chunky Sports has worked with certain third-party social media providers to offer you their social networking services through our Services. For example, you can use third-party social networking services to share information about your experience on our Services with your friends and followers on those social networking services. These social networking services may be able to collect information about you, including your activity on our Services. These third-party social networking services also may notify your friends, both on our Services and on the social networking services themselves, that you are a user of our Services or about your use of our Services, in accordance with applicable law and their own privacy policies. If you choose to access or make use of third-party social networking services, we may receive information about you that you have made available to those social networking services, including information about your contacts on those social networking services. Your interactions with those social networking services are governed by the privacy policy of the company providing the relevant service.

You also may be able to link an account from a social networking service (e.g., Facebook, Google+, Yahoo!) to an account through our Services. This may allow you to use your credentials from the other site or service to sign in to certain features on our Services. If you link your account from a third-party site or service, we may collect information from those third-party accounts, and any information that we collect will be governed by this Privacy Notice.
</div>
<div className='terms-paragraph'>
<a id="six"><h2>6. Links to Other Websites</h2></a>

The Services may contain links to other websites or online services that are operated and maintained by third parties and that are not under our control or maintained by us. Such links do not constitute an endorsement by us of those other websites, the content displayed therein, or the persons or entities associated therewith. This Privacy Notice does not apply to this third-party content. We encourage you to review the privacy policies of these third-party websites or services.
</div>
<div className='terms-paragraph'>
<a id="seven"><h2>7. Data Security and Data Integrity</h2></a>

The security and confidentiality of your personal information is very important to the Chunky Sports. We have implemented technical and organizational safeguards to appropriately protect your personal information against accidental, unauthorized, or unlawful access, use, loss, destruction or damage.

Still, no system can be guaranteed to be 100% secure.

As a result, while we strive to protect your information and privacy, we cannot guarantee or warrant the security of any information you disclose or transmit to the Services and cannot be responsible for the theft, destruction, or inadvertent disclosure of your information.
</div>
<div className='terms-paragraph'>
<a id="eight"><h2>8. Data Retention</h2></a>


We will retain your information only for as long as reasonably necessary for the purposes set out above, considering criteria such as applicable rules on statute of limitations, legal requirements and the duration of your use of our website and receipt of our Services. For additional Region Specific Disclosures, see below.
</div>
<div className='terms-paragraph'>
<a id="nine"><h2>9. Children's Privacy</h2></a>

The Services do not knowingly collect, use, or disclose Personal Information from children under the age of 13 unless permitted by law. If you have reason to believe that a child under the age of 13 has provided personal data to us except as permitted by applicable law, please contact us using any of the methods described in the "Contact Us" section of this Privacy Notice, and we will endeavor to delete that data from our systems.

For children located in jurisdictions within the European Economic Area, we comply with the age limits applicable in each Member State. In these instances, the references above to the age of 13 will be deemed to be references to the age limits applicable in each Member State.
</div>
<div className='terms-paragraph'>
<a id="ten"><h2>10. Communications Preferences and Access</h2></a>

You may choose to receive promotional emails, newsletters, push notifications, and similar communications from us. You may opt out of receiving commercial emails from us by clicking on the opt-out or "unsubscribe" link included in the commercial e-mails you receive. You may opt out of receiving push notifications by turning off push notifications at the device level. Please note that opt-out requests may take some time to be effective. Your opt-out request will not apply to messages that you request or that are not commercial in nature. For example, we may contact you concerning any purchases you have made with us, even if you opt out of receiving unsolicited commercial email messages.

If your information changes, or if you no longer desire to use the Services, you may request that we update or remove certain information by signing into your account and making the required changes, by emailing our Customer Support at Privacy@chunkysports.com, or by contacting us by postal mail at the contact information listed below. We will respond to your request within a reasonable time.

You may opt-out of location-based services at any time by editing your device settings.

We will retain your information for as long as your account is active or as needed to provide you services. We use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
</div>
<div className='terms-paragraph'>
<a id="eleven"><h2>11. Changes To This Privacy Notice</h2></a>

We may update this Privacy Notice to reflect changes to our information practices. If we make any material changes we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on the Services prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
</div>
<div className='terms-paragraph'>
<a id="twelve"><h2>12. Contact Us</h2></a>


If you have any questions about this Privacy Notice or the privacy practices of the Services, please contact us by email at Privacy@chunkysports.com.

</div>

<div className='terms-paragraph'>
<a id="twelve"><h2>13. Youtube Privacy</h2></a>

This Site uses Youtube api services to embed videos, data collected from Youtube Api is handled as all sensitive data via this privacy policy.  You can find the google privacy terms <a href="https://policies.google.com/privacy">here</a> or place "https://policies.google.com/privacy" in a browser.

</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

</Box>
  )
}

export default Privacy