import { createContext, useContext, useEffect, useState } from "react";
import auth from "../utils/firebase-config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

const AuthContext = createContext({
  currentUser: null,
  //   register: () => Promise,
  //   login: () => Promise,
  firebaseLogOut: () => Promise,
  //   forgetPassword: () => Promise,
  signInWithTwitter: () => Promise,
  signInWithFacebook: () => Promise,
  signInWithGoogle: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const Unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => {
      Unsubscribe();
    };
  }, []);
  //   function register(email, password) {
  //     return createUserWithEmailAndPassword(auth, email, password);
  //   }

  //   function login(email, password) {
  //     return signInWithEmailAndPassword(auth, email, password);
  //   }
  function firebaseLogOut() {
    return signOut(auth);
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }
  function signInWithFacebook() {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider);
  }

  const signInWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(auth, provider);
  };

  //   function forgetPassword(email) {
  //     return sendPasswordResetEmail(auth, email, { url: "http://localhost:3000/login" })
  //   }

  const value = {
    currentUser,
    // register,
    // login,
    firebaseLogOut,
    signInWithGoogle,
    // forgetPassword,
    signInWithFacebook,
    signInWithTwitter,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
