import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import ChatArea from "../ChatArea/ChatArea";
import { useMediaQuery } from "react-responsive";
import { BsFillChatRightTextFill } from "react-icons/bs";
import CustomAppBar from "./AppBar";
import Scrollbar from "../Scrollbar/Scrollbar";
import { useAuth } from "../../context/AuthContext";
import DrawerContent from "./DrawerContent";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import teamdata from "../Functions/TeamData.json"
import { useStateValue } from "../../StateProvider"


const drawerWidth = 260;
const toolbar = 80;
const chatWidth = 300;

function ResponsiveDrawer(props) {
  const [state,dispatch] = useStateValue();
  const { currentUser, firebaseLogOut } = useAuth();
  const { window, handleLogoutClick } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const DEBUG = props.DEBUG

  // for chat drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [chatOpen, setChatOpen] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 780px)" });

  useEffect(() => {
    if (isMobile) {
      setChatOpen(false);
    } else {
      setChatOpen(false);
    }
  }, [isMobile]);

  const teamdataProps = {
    options: teamdata,
    getOptionLabel: (option) => option.attributes.first_name +" " + option.attributes.last_name,
    
  };

  const hendleNavigate = () => {
    navigate("/home");
  };

  function handleInputChange(event, value) {
    
    if (typeof value === 'object' && value !== null)
    {
      if(DEBUG === 1) console.log("Navigate",value.attributes.first_name + " " + value.attributes.last_name)
      navigate(`/teams/${value.attributes.category}/${value.attributes.username}`)
      if (isMobile) 
      {
        handleDrawerToggle()
      }
    }
  }

  const handleSummaryClick = () => {
    navigate("/summary")
      if (isMobile) 
      {
        handleDrawerToggle()
      }

  }

  const handleOpenTeam = () => {
    navigate("/teamupdate")
      if (isMobile) 
      {
        handleDrawerToggle()
      }

  }

  // Modal for Logout
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // firebase Logout
  const firebaseLogout = () => {
    firebaseLogOut()
      .then((user) => {
        if(DEBUG === 1) console.log("Logout", user);
        navigate("/");
      })
      .catch((error) => console.log(error.message));
  };

  const drawer = (
    <div>
      <DrawerContent
        currentUser={currentUser}
        user={state.user}
        hendleNavigate={hendleNavigate}
        handleOpen={handleOpen}
        handleClose={handleClose}
        firebaseLogout={firebaseLogout}
        handleLogoutClick={handleLogoutClick}
        open={open}
        userLoginData={state.sources}
        handleDrawerToggle={handleDrawerToggle}
        navigate={navigate}
        handleInputChange={handleInputChange}
        teamdataProps={teamdataProps}
        handleSummaryClick={handleSummaryClick}
        handleOpenTeam={handleOpenTeam}
      />
    </div>
  );

  if(DEBUG === 1) console.log("userLoginData in drawer", props.userLoginData)

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: "#000" }}
      >
        <CustomAppBar
          handleDrawerToggle={handleDrawerToggle}
          handleOpen={handleOpen}
          props={props}
          currentUser={currentUser}
          DEBUG={DEBUG}
        />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, overflow:'auto' }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#000",
              color: "#fff !important",
              overflow:'auto',
              "& svg": {
                fill: "#fff",
              },
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#000",
              color: "#fff !important",
              "& svg": {
                fill: "#fff",
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <CustomAppBar /> */}
        <Toolbar />
        <Grid
          item
          container
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            p: { xs: 2, sm: 3 },
            position: "relative",
          }}
        >
          <Box sx={{ width: "100%" }}>{props.children}</Box>

          <Box
            sx={{
              width: "100%",
              // display:{xs:'none',md:'block'},
              maxWidth: chatOpen && `${chatWidth}px`,
              height: "100vh",
              position: "fixed",
              right: "0px",
              top: `${toolbar}px`,
              mt: { xs: -3, md: -2 },
            }}
          >
           { /*<ChatArea
              user={props.user}
              isLoggedin={props.isLoggedin}
              sources={props.sources}
              chatOpen={chatOpen}
              setChatOpen={setChatOpen}
          /> */}
          </Box>
        </Grid>

        {/*!chatOpen && (
          <div
            className="absolute bottom-5 right-5 md:bottom-10 md:right-10 bg-black rounded-full p-3 cursor-pointer"
            onClick={() => setChatOpen(true)}
          >
            <BsFillChatRightTextFill size={20} color="white" />
            <span className="absolute top-0 right-0 px-2 py-1 text-xs font-bold leading-none text-red-100 transform bg-red-600 rounded-full">
              9
            </span>
          </div>
        )*/}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
