import { Typography, Box, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const TeamLink = ({category, subcategory,username, first_name, last_name, id ,logo}) => {
  return (
    <Grid item key={id} xs={12} sm={12} md={12}>
      <Box  sx={{ ':hover':{ background:'#fff' }, display:'flex', width:'100%', alignItems:'center' }}>
      
        <div>
        <Link to={`/teams/${category}/${username}`} >
        <img className="w-[30px] bg-cover rounded-full" src={logo} alt={first_name + " " + last_name} />
        </Link>
        </div>
        <div>
        <Link to={`/teams/${category}/${username}`} >
        <Typography sx={{ fontSize:12,ml:1, fontWeight:'bold', color:'#000' }}>{first_name + " " + last_name}</Typography>
        </Link>
        </div>
     
      </Box>
    </Grid>
  )
}

export default TeamLink