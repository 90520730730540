import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect, Fragment } from "react";
import "./Login.css";
import Loginform from "../Loginform/Loginform";

const Login = ({ open, handleClose, handleSuccessfulAuth, DEBUG }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        mt: { md: 4 },
      }}
    >
      <Loginform
        handleSuccessfulAuth={handleSuccessfulAuth}
        handleClose={handleClose}
        DEBUG={DEBUG}
      />
    </Box>
  );
};

export default Login;
