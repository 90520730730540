import React, { useState, useEffect, Fragment } from "react"
import "./Team.css"
import Mupdates from "../Mupdates/Mupdates"
import Status from "../Status/Status"
import Tnews from "../Tnews/Tnews"
import { Box } from "@mui/material"

const Team = (props) => {

 
  
  return (
    <Box sx={{  }}>
      <Status/>
      <Tnews user={props.user} DEBUG={props.DEBUG} isLoggedin={props.isLoggedin} />
          
    </Box>   
  )
}

export default Team