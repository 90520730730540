import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export default function LogOut({
  handleClose,
  open,
  handleLogoutClick,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          background: "transparent !important",
          "& .MuiBackdrop-root-MuiModal-backdrop": {
            backgroundColor: "none",
          },
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Log Out
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 20 }}>
            Are you sure you want to log out
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              border: "2px solid #000",
              fontWeight: "bold",
              background: "#000 !important",
              width: "100%",
              py: 1,
              fontSize: 18,
              mt: 3,
            }}
            onClick={() => {
              handleLogoutClick();
              handleClose();
            }}
          >
            Logout
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
