import React, { useState, useEffect } from 'react'
import "./TeamLinks.css"
import axios from "axios"
import TeamLink from "./TeamLink"
import { Grid, Typography } from '@mui/material';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}api/v1`,
});

const TeamLinks = (props) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const [sources, setSources] = useState([])
  const DEBUG = props.DEBUG
  const getSub = (subcategory) => {
    return sources
      .filter((source) => source.attributes.subcategory === subcategory)
      .sort(
        (a, b) =>
          a.attributes.subcategory - b.attributes.subcategory
      );
  };

  const setMLB = () => {

    return (
      <>

        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>Major League Baseball (MLB)</Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>American League</Typography>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="tlink-name">East</div>
              {getSub("AL East").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">Central</div>
              {getSub("AL Central").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">West</div>
              {getSub("AL West").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>National League</Typography>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <div className="tlink-name">East</div>
              {getSub("NL East").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">Central</div>
              {getSub("NL Central").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">West</div>
              {getSub("NL West").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
          </div>
        </div>
      </>



    );

  }


  const setNBA = () => {

    return (
      <>


        <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>National Basketball Association (NBA)</Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>Eastern</Typography>
        <div className="grid grid-cols-3 gap-6">
          <div>
            <div className="tlink-name">Atlantic</div>
            {getSub("Atlantic Division").map((post) => (
              <TeamLink
                key={post.id}
                id={post.id}
                category={post.attributes.category}
                username={post.attributes.username}
                first_name={post.attributes.first_name}
                last_name={post.attributes.last_name}
                subcategory={post.attributes.subcategory}
                logo={post.attributes.logo30}
              />
            ))
            }

          </div>
          <div>
            <div className="tlink-name">Central</div>
            {getSub("Central Division").map((post) => (
              <TeamLink
                key={post.id}
                id={post.id}
                category={post.attributes.category}
                username={post.attributes.username}
                first_name={post.attributes.first_name}
                last_name={post.attributes.last_name}
                subcategory={post.attributes.subcategory}
                logo={post.attributes.logo30}

              />
            ))
            }

          </div>
          <div>
            <div className="tlink-name">South East</div>
            {getSub("Southeast Division").map((post) => (
              <TeamLink
                key={post.id}
                id={post.id}
                category={post.attributes.category}
                username={post.attributes.username}
                first_name={post.attributes.first_name}
                last_name={post.attributes.last_name}
                subcategory={post.attributes.subcategory}
                logo={post.attributes.logo30}

              />
            ))
            }

          </div>
        </div>


        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>Western</Typography>
          <div className="grid grid-cols-3 gap-6">

            <div>
              <div className="tlink-name">Northwest</div>
              {getSub("Northwest Division").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">Pacific</div>
              {getSub("Pacific Division").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">South West</div>
              {getSub("Southwest Division").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
          </div>
        </div>
      </>



    );

  }

  const setNFL = () => {
    return (
      <>
        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>National Football League (NFL)</Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>NFC</Typography>
          <div className="grid grid-cols-4 gap-6">
            <div>
              <div className="tlink-name">NFC East</div>
              {getSub("NFC East").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }
            </div>

            <div>
              <div className="tlink-name">NFC South</div>
              {getSub("NFC South").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">NFC North</div>
              {getSub("NFC North").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }


            </div>
            <div>
              <div className="tlink-name">NFC West</div>
              {getSub("NFC West").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }</div>
          </div>
        </div>

        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>AFC</Typography>
          <div className="grid grid-cols-4 gap-6">

            <div>
              <div className="tlink-name">AFC East</div>
              {getSub("AFC East").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }
            </div>

            <div>
              <div className="tlink-name">AFC South</div>
              {getSub("AFC South").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">AFC North</div>
              {getSub("AFC North").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }


            </div>
            <div>
              <div className="tlink-name">AFC West</div>
              {getSub("AFC West").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }</div>
          </div>
        </div>
      </>

    );
  }

  const setNHL = () => {
    return (
      <>
        <div className="container mx-auto">
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', width: '100%', textAlign: 'center', color: '#000' }}>National Hockey League (NHL)</Typography>
          <div className="grid grid-cols-4 gap-6">
            <div>
              <div className="tlink-name">Atlantic Division</div>
              {getSub("Atlantic").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }
            </div>

            <div>
              <div className="tlink-name">Metroplitan Division</div>
              {getSub("Metropolitan").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }

            </div>
            <div>
              <div className="tlink-name">Central</div>
              {getSub("Central NHL").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }


            </div>
            <div>
              <div className="tlink-name">Pacific Division</div>
              {getSub("Pacific").map((post) => (
                <TeamLink
                  key={post.id}
                  id={post.id}
                  category={post.attributes.category}
                  username={post.attributes.username}
                  first_name={post.attributes.first_name}
                  last_name={post.attributes.last_name}
                  subcategory={post.attributes.subcategory}
                  logo={post.attributes.logo30}

                />
              ))
              }</div>
          </div>
        </div>


      </>

    );
  }


  useEffect(() => {

    async function getSources() {
      const response = await client.get("/sourcenames");
      setSources(response.data.data);
      if (DEBUG === 1) console.log("Teamlinks", response)
    }

    getSources();
  }, []);





  return (
    <div className="" style={{}}>
      <Grid item container xs={12}>
        {props.league === "NFL" &&

          setNFL()}

        {props.league === "NBA" &&

          setNBA()}

        {props.league === "NHL" &&

          setNHL()}

        {props.league === "MLB" &&

          setMLB()}


      </Grid>
    </div>
  );
}

export default TeamLinks