import React, { useState, useEffect } from "react";
/* import {
  FaVideo,
  FaImage,
  FaRegImages,
  FaGrinAlt,
  FaRegGrinAlt,
} from "react-icons/fa"; */
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import axios from "axios";
import Lumpitems from "../Lumpitems/Lumpitems";
import { linkHandler, timeSince } from "../Functions/Functions"
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useStateValue } from "../../StateProvider";


import "./Tnews.css";
import LoadingSpinner from "../Loading/Loading";

const Tnews = (props) => {
  const params = useParams();
  console.log(params);
  console.log("TEAM NEWS PROPS", props)

  const DEBUG = props.DEBUG
  const user = props.user
  const isLoggedin = props.isLoggedin

  const cookies = new Cookies();
  const [state, dispatch] = useStateValue();
  const [source, setSource] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);


  useEffect(() => {
    if (Object.keys(params).length === 0) {
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/daily/20`;
      if (DEBUG === 1) console.log("Empty");
    }

    const team = `${params.id}`
    const temp = ""

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/pages`;

    axios
      .post(url, { "id": temp, "page": page, "pages": 1, "team": team })
      .then((response) => {
        setSource(response.data.lumps);
        setPage(response.data.currentpage)
        setLoaded(true);
        if (DEBUG === 1) console.log("Team Data", response.data);
      })
      .catch((response) => console.log(response));

    setLoaded(false);

  }, [params.id]);

  if (DEBUG === 1) console.log(loaded, 'loaded')

  function getData(pages) {
    let temp = ""

    const team = `${params.id}`


    if (temp == null) {
      temp = '999999'
    }

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/pages`;



    axios
      .post(url, { "id": temp, "page": page, "pages": pages, "team": team })
      .then((response) => {
        setSource([...source, ...response.data.lumps]);
        setPage(response.data.currentpage)
        if (DEBUG === 1) {
          console.log("Timeout data", response.data.data);
          console.log("Page", page)
          console.log("Source Length", source.length)
        }

      })
      .catch((response) => console.log(response));


  }




  let server

  server = "https://dm1r1mwnqdyan.cloudfront.net"

  const handleScroll = (e) => {
    if (DEBUG === 1) console.log("Scroll")
  }


  const fetchData = async () => {
    const requestAddress = `${process.env.REACT_APP_API_SERVER}api/v1/getdata`
    // const token = localStorage.getItem('mytoken');
    let token = cookies.get("token");
    if (token == null) {
      token = "";
    }

    if (DEBUG === 2) console.log("TNEWS Token:", token)

    let lastupdated = localStorage.getItem("last_updated")

    lastupdated = localStorage.getItem("last_updated")


    if (DEBUG === 2) console.log("TNEWS Last Updated Date: ", lastupdated)
    console.log("DEBUG", DEBUG)
    //let currentdate = new Date(); 
    //let current = currentdate.getFullYear() +"-"+(currentdate.getMonth()+1)+"-"+("0" + currentdate.getDate()).slice(-2)+"T"+currentdate.getHours()+":"+currentdate.getMinutes() + ":"+currentdate.getSeconds() +"."+currentdate.getMilliseconds()+"-"+(currentdate.getTimezoneOffset()/60)+":00";
    //console.log("Current", current)



    const response = await axios.post(requestAddress, {
      id: token,
      lastupdated: lastupdated,
      verb: "update",
      page: "1",
      pages: "3"
    });

    if (DEBUG === 1) console.log(response.data.lastupdated)

    await dispatch({
      type: 'SET_LAST_UPDATED',
      lastupdated: response?.data?.lastupdated
    })
    localStorage.setItem("last_updated", response.data.lastupdated)

    if (DEBUG === 2) console.log("TNEWS Fetch Data", response);

    const newcomments = response?.data?.comments;
    if (newcomments && newcomments.length > 0) {
      // Do something with the comments array
      await dispatch({
        type: 'ADD_COMMENTS',
        comments: response?.data?.comments
      })
    }

    const apiArray = response?.data?.lumps;
    if (apiArray && apiArray.length > 0) {

      await dispatch({
        type: 'ADD_NOTIFICATIONS',
        notifications: apiArray
      })
    }





    // Get stored data from local storage
    const storedArray = JSON.parse(localStorage.getItem('storedArray')) || [];

    let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
    const itemsNotMatched = JSON.parse(localStorage.getItem('itemsNotMatched')) || [];
    // console.log(storedArray.length)

    // Check and compare the items of apiArray and storedArray
    if (storedArray.length > 0) {
      for (let i = 0; i < apiArray.length; i++) {
        const foundItem = storedArray.filter((item) => item.title === apiArray[i].title);
        console.log(foundItem)
        if (foundItem.length == 0) {
          notificationCount++;
          itemsNotMatched.push(apiArray[i]);
        }
      }
    }

    //Add to Notifications State



    // Update stored data in local storage
    localStorage.setItem('storedArray', JSON.stringify(apiArray));
    localStorage.setItem('notificationCount', notificationCount);
    localStorage.setItem('itemsNotMatched', JSON.stringify(itemsNotMatched));
    // Set notification count in state
    setNotificationCount(notificationCount);


  };

  useEffect(() => {
    let intervalId = null;

    if (state.lumps.length === 0 && state.sources.length === 0) {
      fetchData();
    }

    intervalId = setInterval(() => {
      fetchData();
      // console.log(notificationCount)
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  return (
    <div id="scrollable" className="" onScroll={handleScroll}>
      {loaded ? source.map((post) => (
        <Lumpitems
          id={post.id}
          server={server}
          username={post.username}
          name={post.name}
          category={post.category}
          image={post.image}
          title={post.title}
          link={post.link}
          created_at={post.created_at}
          description={post.description}
          youtube={post.youtube}
          linkHandler={linkHandler}
          timeSince={timeSince}
          user={user}
        />
      )) : <LoadingSpinner />}

      <Grid item xs={12} sx={{ mt: 2 }} >
        <Box
          sx={{
            my: { xs: 2, sm: 1.5 },
            alignItems: "center",
            display: "flex",
          }}
        >

          <Button onClick={() => getData(24)}>Load Last 24 Hours</Button>

        </Box>
      </Grid>
    </div>
  );
};

export default Tnews;
