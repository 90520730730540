import  React,{useEffect, useState} from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Avatar, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import DropDown from "../Dropdown/HoverDropdown";

import TwitterIcon from '@mui/icons-material/Twitter';
import InfoIcon from '@mui/icons-material/Info';
import Card from '@mui/material/Card';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './notifications.css';
import Cookies from "universal-cookie";
import { useStateValue } from "../../StateProvider";
import UserSidebar from "../UserSidebar/UserSidebar";

import { SwipeableDrawer, List, ListItem, ListItemButton, ListItemText, ListItemSecondaryAction } from '@mui/material';


import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const style = {
  position: 'absolute',
  top: '14%',
  borderRadius: '25px 0px 25px 25px',
  left: '75%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'white',
  border: '2px solid transparent',
  // boxShadow: 24,
  p: 1,
};



const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));


// function removeItem(title) {
//   // const newArray = itemsNotMatched.splice(itemsNotMatched.findIndex(obj => obj.title === title), 1);
//   const newArray = itemsNotMatched.filter(obj => obj.title !== title);
  
//   let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
//   const newNotificationCount = notificationCount - 1;
//   if(newNotificationCount<0) {
//     localStorage.setItem('notificationCount', 0);
//   } else {
//     localStorage.setItem('notificationCount', newNotificationCount);
//   }
// }

function NotificationsSidebar(props) {
  const { open, onClose } = props;
  const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem('itemsNotMatched')) || []);
  const [showAll, setShowAll] = useState(true);
  const cookies = new Cookies();
  const token = cookies.get("token");


  const handleMarkAsRead = (index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications[index].read = true;
    setNotifications(updatedNotifications);
    localStorage.setItem('itemsNotMatched', JSON.stringify(updatedNotifications));
    let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
    const newNotificationCount = notificationCount - 1;
    if(newNotificationCount<0) {
      localStorage.setItem('notificationCount', 0);
    } else {
      localStorage.setItem('notificationCount', newNotificationCount);
    }
  };

  const handleDelete = (index) => {
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);
    localStorage.setItem('itemsNotMatched', JSON.stringify(updatedNotifications));
    let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
    const newNotificationCount = notificationCount - 1;
    if(newNotificationCount<0) {
      localStorage.setItem('notificationCount', 0);
    } else {
      localStorage.setItem('notificationCount', newNotificationCount);
    }
  };

  
 
  
  const handleShowAll = () => {
    setShowAll(true);
  };

  const handleShowUnread = () => {
    setShowAll(false);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      disableSwipeToOpen={false}
      PaperProps={{ className: 'notification-sidebar' }}
    >
      <div className="notification-header">
        <h2 className="notification-title">Notifications</h2>
        <IconButton onClick={onClose} className="notification-close">
          <CloseIcon />
        </IconButton>
      </div>
      <div className="notification-buttons">
        
        <Button onClick={handleShowAll}  className={`notification-button ${showAll ? 'active' : ''}`}>
          All
        </Button>
        <Button onClick={handleShowUnread} className={`notification-button ${!showAll ? 'active' : ''}`}>
          Unread
        </Button>
      </div>
      <List className="notification-list">
      {token===null || token===undefined?<p style={{color:"black"}}>Login required</p>:null}
        {notifications.length===0?<p style={{color:"black"}}>Empty Notifications</p>:null}
        {notifications?.map((notification, index) => {
          if (!showAll && notification.read) {
            return null;
          }
          return (
            <ListItem disablePadding key={index}>
              <div onClick={() => handleMarkAsRead(index)}>
                <a class="notification-item" href={`https://chunkydata.com/lumps/${notification?.id}`} target="_blank" key={index}>
                  <img class="notification-image" src={notification?.image ? notification.image : "https://via.placeholder.com/40"} alt={notification.title} />
                  <div class="notification-content">
                    <span class="notification-team">{notification?.name}</span>
                    <span class="notification-title">{notification?.title ? notification.title : notification?.link ? notification.link : null}</span>
                    <p style={{ fontStyle: "italic", fontSize: "12px", marginTop: "10px", color: "#666" }}>
  {new Date(notification?.updated_at).toISOString()}
</p>
                  </div>
                  
                </a>
              </div>
              <ListItemSecondaryAction>
                <IconButton size="small" onClick={() => handleDelete(index)} className="notification-button delete">
                  <DeleteIcon />
                </IconButton>
                {!notification.read && (
                  <IconButton size="small" onClick={() => handleMarkAsRead(index)} className="notification-button check">
                    <CheckIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
   

    </SwipeableDrawer>
  );
}

export default function CustomAppBar({ handleDrawerToggle, props, currentUser , handleOpen, DEBUG }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState(null);
  const [itemsNotMatched, setItemsNotMatched] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [state, dispatch] = useStateValue();


  useEffect(() => {
    const interval = setInterval(() => {
      let notiNo = Number(localStorage.getItem('notificationCount')) || 0;
      const notiItems = JSON.parse(localStorage.getItem('itemsNotMatched')) || [];
  
      if (notiNo > 60) {
        setNotificationCount(0);
        setItemsNotMatched([]);
        localStorage.setItem('notificationCount', 0);
        localStorage.setItem('itemsNotMatched', JSON.stringify([]));
      } else {
        setNotificationCount(notiNo);
        setItemsNotMatched(notiItems);
      }
    }, 30000); // 30 seconds interval
  
    return () => clearInterval(interval);
  }, []);
  
  const handleOpenUser = () => {
    setOpenUser(true)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={()=>{handleOpenUser(); handleMenuClose()}}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Settings</MenuItem>      
      <MenuItem onClick={()=>{handleOpen(); handleMenuClose() }}>Log Out</MenuItem>

    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {state.user !=undefined || state.user!=null?
      <div>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon onClick={()=>setOpen(true)}/>
          </Badge>
        </IconButton>
        <a onClick={()=>setOpen(true)} style={{textDecoration:"none"}}>Notifications</a>
      </MenuItem></div>:null}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  return (
    <Box sx={{ flexGrow: 1 }}>
      <div>
      
      <NotificationsSidebar open={open} onClose={() => setOpen(false)} />
     <UserSidebar DEBUG={DEBUG} user={state.user} open={openUser} onClose={() => setOpenUser(false)} />

    </div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, display: { md: "none" } }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            noWrap
            component="div"
            // sx={{ display: { xs: "none", sm: "block" } }}
          >
            Chunky Sports
          </Typography>

          {/* Dropdowns */}
          <Box
            sx={{
              maxWidth: "120px",
              width: "100%",
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              ml: 3,
            }}
          >
            <DropDown
              user={props.user}
              isLoggedin={props.isLoggedin}
              sources={props.sources}
              league="MLB"
            />
            <DropDown
              user={props.user}
              isLoggedin={props.isLoggedin}
              sources={props.sources}
              league="NFL"
            />
            <DropDown
              user={props.user}
              isLoggedin={props.isLoggedin}
              sources={props.sources}
              league="NHL"
            />
            <DropDown
              user={props.user}
              isLoggedin={props.isLoggedin}
              sources={props.sources}
              league="NBA"
            />
          </Box>

          {/* End sections dropdown */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>

            {state.user !=undefined || state.user!=null?
            <div>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon onClick={()=>setOpen(true)}/>
              </Badge>
            </IconButton></div>:null}

            {/*  show Account for user */}
            
            {props.user.username || currentUser ? (
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  src={ !currentUser ? props.user.image : currentUser.image }
                  alt={ !currentUser ? props.user.email : currentUser.email }
                  sx={{ width: 40, height: 40, textTransform: "capitalize", color:'#000' }}
                />
              </IconButton>
            ) : (
              <Link
                to="/login"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Tooltip title="login">
                  <AccountCircle sx={{ width: 35, height: 35 }} />
                </Tooltip>
              </Link>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
