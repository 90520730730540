
import axios from "axios"
// import Cookies from "universal-cookie";
// import { useStateValue } from "../../StateProvider";
// import React, { useState, useEffect, useCallback } from "react";


export const linkHandler = (id,link,userid) => {

  return function realLinkHandler(event) {
    
    event.preventDefault()
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/click`;
    console.log("Args", id, link, userid)
   axios
    .post(url,{"lump": id, "userid": userid})
    .then((response) => {
     
      console.log("Click Result", response);
    })
    .catch((response) => console.log(response));

    window.open(link,"_blank")


  }
  
}

export const timeSince = (timeString) => {

  const date = new Date(timeString);
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year(s) ago";      
  }
  
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month(s) ago";
  }
 
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day(s) ago";
  }
 
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour(s) ago";
  }    
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minute(s) ago";
  }
 
  return Math.floor(seconds) + " second(s) ago";
}

// export const fetchData = async (DEBUG) => {
//   const [state, dispatch] = useStateValue();
//   const requestAddress = `${process.env.REACT_APP_API_SERVER}api/v1/getdata`
//   const [notifications, setNotifications] = useState(0);
//   // const token = localStorage.getItem('mytoken');
//   const cookies = new Cookies();
//   let token = cookies.get("token");
//   if (token == null) {
//     token = "";
//   }

//   console.log("Token:",token)

//   let lastupdated = localStorage.getItem("last_updated")
//   if (lastupdated ==! "") {
//     lastupdated = localStorage.getItem("last_updated")


//     console.log("Last Updated Date: ", lastupdated)
//     //let currentdate = new Date(); 
//     //let current = currentdate.getFullYear() +"-"+(currentdate.getMonth()+1)+"-"+("0" + currentdate.getDate()).slice(-2)+"T"+currentdate.getHours()+":"+currentdate.getMinutes() + ":"+currentdate.getSeconds() +"."+currentdate.getMilliseconds()+"-"+(currentdate.getTimezoneOffset()/60)+":00";
//     //console.log("Current", current)



//     const response = await axios.post(requestAddress, {
//       id: token,
//       lastupdated: lastupdated,
//       verb: "update",
//       page: "1",
//       pages: "3"
//     });

//     if (DEBUG === 1) console.log(response.data.lastupdated)

//     await dispatch({
//       type: 'SET_LAST_UPDATED',
//       lastupdated: response?.data?.lastupdated
//     })
//     localStorage.setItem("last_updated", response.data.lastupdated)

//     if (DEBUG === 2) console.log("Fetch Data", response);

//     const newcomments = response?.data?.comments;
//     if (newcomments && newcomments.length > 0) {
//       // Do something with the comments array
//       await dispatch({
//         type: 'ADD_COMMENTS',
//         comments: response?.data?.comments
//       })
//     }

//     const apiArray = response?.data?.lumps;
//     if (apiArray && apiArray.length > 0) {

//       await dispatch({
//         type: 'ADD_NOTIFICATIONS',
//         notifications: apiArray
//       })
//     }





//     // Get stored data from local storage
//     const storedArray = JSON.parse(localStorage.getItem('storedArray')) || [];

//     let notificationCount = Number(localStorage.getItem('notificationCount')) || 0;
//     const itemsNotMatched = JSON.parse(localStorage.getItem('itemsNotMatched')) || [];
//     // console.log(storedArray.length)

//     // Check and compare the items of apiArray and storedArray
//     if (storedArray.length > 0) {
//       for (let i = 0; i < apiArray.length; i++) {
//         const foundItem = storedArray.filter((item) => item.title === apiArray[i].title);
//         console.log(foundItem)
//         if (foundItem.length == 0) {
//           notificationCount++;
//           itemsNotMatched.push(apiArray[i]);
//         }
//       }
//     }

//     //Add to Notifications State



//     // Update stored data in local storage
//     localStorage.setItem('storedArray', JSON.stringify(apiArray));
//     localStorage.setItem('notificationCount', notificationCount);
//     localStorage.setItem('itemsNotMatched', JSON.stringify(itemsNotMatched));
//     // Set notification count in state
//     setNotificationCount(notificationCount);

//   }
// };