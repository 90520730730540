import { Box, Button, TextField, Typography} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import Autocomplete from "@mui/material/Autocomplete";
import teamdata from "../Functions/TeamData.json";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Avatar } from "@mui/material";
import Upload from "../Upload/Upload"



const Registration = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [image,setImage] = React.useState("")
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])
    if (DEBUG === 2) console.log("File",selectedFile)
  }

  const DEBUG = props.DEBUG

  const [imagedetails,setImageDetails] = useState(
    {
      image:"",
      filename: "",
      imageid: ""

    }
  )

  const setImageFile = (data) => {
    setImage(data.url)
    setImageDetails({
      image: data.url,
      filename: data.filename,
      imageid: data.imagefileid
    }

    )
  }
  if (DEBUG === 1) console.log("ImageDetails",imagedetails)

  const navigate = useNavigate();
  const [formisValid,setFormIsValid] = useState(false)
  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    username: "",
    mobile: "",
    firstname: "",
    lastname: "",
    slogan: "",
    bio: "",
    registrationErrors: "",
    team: "",
    displayname: "",
    privacy: "",
    image: "",
    filename: "",
    imageid: ""
  });

  const [validation, setvalidation] = useState({
    email: "",
    emailSuccess: "",
    password: "",
    passwordSuccess: "",
    username: "",
    usernameSuccess: "",
    password_confirmation: "",
    password_confirmationSuccess: "",
    team: "",
    teamSuccess: ""
  });

  if (DEBUG === 2) console.log("Validation",validation)
  function checkisFormValid()
   {
    
    let valid = 0
    console.log("Valid",valid)
   
    if(!validation.username)
    {
      valid++
      console.log("InValid Username",valid)
    }
    if(!validation.password)
    {
      valid++
      console.log("InValid Password",valid)
    }
    if(!validation.team)
    {
      valid++
    }
    if(!validation.username)
    {
      valid++
    }
    if (valid > 0)
    {
      setFormIsValid(true)
      return true

    }
    else
    {
      setFormIsValid(false)
      return false
    }

    console.log("Valid",valid)

  }
  

  const passwordConfirmValidator = () => {

    if (state.password === state.password_confirmation) {
      setvalidation({ ...validation, password_confirmationSuccess: "Password Confirmed", password_confirmation: "" });
      checkisFormValid()
      return true
    }
    else {
      setvalidation({ ...validation, password_confirmationSuccess: "", password_confirmation: "Password doesn't match" });
      checkisFormValid()
      return false
    }

  }

  const teamValidator = () => {

    if (state.team !== "") {
      setvalidation({ ...validation, teamSuccess: "Team selection is Good", team: "" });
      checkisFormValid()
      return true
    }
    else {
      setvalidation({ ...validation, team: "Must select one team", teamSuccess: "" });
      checkisFormValid()
      return false
    }
  }

  const teamdataProps = {
    options: teamdata,
    getOptionLabel: (option) =>
      option.attributes.first_name + " " + option.attributes.last_name,
  };

  const handleSubmit = (e) => {
    e.preventDefault();



    if (DEBUG === 2) console.log("Form submitted");

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/users`;
    if (DEBUG === 2) console.log("state", {
      ...state,
    });
    axios
      .post(url, {
        user: {
          ...state,
        },
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'crossdomain': true,
          'Origin': 'chunkysports.com'
        }
      })
      .then((response) => {
        if (DEBUG === 2) console.log("Successful response", response);
        if (response.data.isLoggedin) {
          props.handleSuccessfulAuth(response.data);
          navigate("/");
        }
      })
      .catch((response) => console.log("Failed response", response));

  };

  const passwordValidator = () => {
    if (state.password.length !== 0) {
      setvalidation({ ...validation, password: "", passwordSuccess: "" });
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const minPassLength = 8

      const passwordLength = state.password.length;

      const upperpass = uppercaseRegExp.test(state.password)
      const lowerpass = lowercaseRegExp.test(state.password)
      const digitpass = digitsRegExp.test(state.password)

      let pass
      pass = 0
      if (passwordLength < minPassLength) {
        pass++
      }
      if (!upperpass) {
        pass++
      }
      if (!lowerpass) {
        pass++
      }
      if (!digitpass) {
        pass++
      }

      if (pass !== 0) {
        setvalidation({ ...validation, password: "Password Complexity: 8 min, 1 from Upper,Lower and Number", passwordSuccess: "" });
        checkisFormValid()
        return false
      }
      else {
        setvalidation({ ...validation, password: "", passwordSuccess: "Password is Good" });
        checkisFormValid()
        return true
      }

    }
  };

  const emailValidator = () => {
    setvalidation({ ...validation, email: "", emailSuccess: "" });

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!state.email || regex.test(state.email) === false) {
      setvalidation({
        ...validation,
        email: "Email Format is not valid",
        emailSuccess: "",
      });
      checkisFormValid()
      return false;
    } else {
      const email = { email: state.email };
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/isemailexists`;

      console.log("Email Address", state.email);
      axios
        .post(url, email)
        .then((response) => {
          if (DEBUG === 2) console.log("Successful response", response);
          if (DEBUG === 2) console.log("Email Results", response.data.successful);
          if (response.data.successful) {
            setvalidation({
              ...validation,
              emailSuccess: `${state.email} is available`,
              email: "",
            });
            checkisFormValid()
            return true;
          } else {
            setvalidation({
              ...validation,
              email: `${state.email} is NOT available`,
              emailSuccess: "",
            });
            checkisFormValid()
            return false;
          }
        })
        .catch((response) => console.log("Failed response", response));
    }
  };

  const usernameValidator = () => {
    setvalidation({ ...validation, username: "", usernameSuccess: "" });



    if (state.username.length < 5) {
      setvalidation({
        ...validation,
        username: "Username must be 5 characters or longer",
        usernameSuccess: "",
      });
      checkisFormValid()
      return false;
    } else {
      const username = { username: state.username };
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/isusernameexists`;


      axios
        .post(url, username)
        .then((response) => {
          if (DEBUG === 2) console.log("Successful response", response);

          if (response.data.successful) {
            setvalidation({
              ...validation,
              usernameSuccess: `${state.username} is available`,
              username: "",
            });
            checkisFormValid()
            return true;
          } else {
            setvalidation({
              ...validation,
              username: `${state.username} is NOT available`,
              usernameSuccess: "",
            });
            checkisFormValid()
            return false;
          }
        })
        .catch((response) => console.log("Failed response", response));
    }
  };
  function handleTeamChange(event, value) {
    if (typeof value === "object" && value !== null) {
      setState({ ...state, team: value.attributes.username });
     
    }
    if (DEBUG === 2) console.log("State after setting team", state);
    checkisFormValid();
  }

  const handleImageUpload = (event) => {
    event.preventDefault()

  }

  const handleChange = (event) => {
    if (DEBUG === 2) console.log("State:", state);
    setState({ ...state, [event.target.name]: event.target.value });
    
  };

  //const handleChangePrivacy = (event) => {
   // setState({...state, privacy: event.target.value });
  //};

  const handleUploadFile = (event) => {
    event.preventDefault()
    let formData
    formData = new FormData();
    formData.append("image", selectedFile);
    const url = `${process.env.REACT_APP_API_SERVER}api/v1/upload`
        
    
      axios.post(url,formData)
      .then((response) => {
        if (DEBUG === 2) console.log("Upload Response", response.data)
        setImage(response.data.url)
        setImageFile(response.data)
        setState({...state, image: response.data.url, filename: response.data.filename, imageid: response.data.imagefileid });
       
       
      })
      .catch((response) =>
        console.log("Error Failed Logout response", response)
      );
      //const response = axios({
       // method: "post",
        //url: "https://api.cloudflare.com/client/v4/accounts/a3e4b4c9ee93f2ba5f3dfbee8f8fb17c/images/v1",
        //data: {...formData.getHeaders(),
        //headers: {formData,
         // Authorization: "Bearer 9Xuc0qjgzyGPy0iotWp29NzOMPJSW9a1AEB1gITI"
         //}},
     // });
      //console.log("Image Upload Response",response)
   
  }

 

  return (
    <div>
      <Box
        sx={{
          maxWidth: "580px",
          width: { xs: "100%", sm: "460px" },
          margin: "auto",
          background: "#fff",
          p: { xs: 2, sm: 3 },
          borderRadius: "12px",
          boxShadow: 24,
        }}
      >
          <Upload
                handleClose={handleClose}
                open={open} 
                handleImageUpload={handleImageUpload}
                handleUploadFile={handleUploadFile}
                handleFileSelect={handleFileSelect}
                setImageFile={setImageFile}
                selectedFile={selectedFile} 
                image={image}           
              />
        <form
          onSubmit={handleSubmit}
          action=" "
          method="post"
          id="contact_form"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Chunky Sports Signup Form
          </Typography>
          <br />
          <center>
            <Avatar
              alt={"Unknown"}
              src={image}
              sx={{
                width: 150,
                height: 150,
                color: (theme) => theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer"
                
              }}

            />
            <Button
            type="button"
            variant="contained"
            onClick={handleOpen}
            sx={{
              py: 1.5,
              mt: 2,
              background: "#1877f2 !important",
              color: "#fff",
            }}
          >
            Upload Avatar
          </Button>
        
          </center>

          <div>

          </div>

          <label style={{ fontWeight: "bold" }}>E-Mail</label>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            name="email"
            label="E-Mail Address"
            value={state.email}
            onChange={handleChange}
            onBlur={emailValidator}
            required
            sx={{ mb: 2, mt: 1 }}
          />
          <div>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "red", fontWeight: "800" }}
            >
              {validation.email}
            </label>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "green", fontWeight: "800" }}
            >
              {validation.emailSuccess}
            </label>
          </div>
          <label style={{ fontWeight: "bold" }}>Password</label>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            name="password"
            label="Password"
            value={state.password}
            onChange={handleChange}
            onBlur={passwordValidator}
            required
            sx={{ mb: 2, mt: 1 }}
          />
          <div>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "red", fontWeight: "800" }}
            >
              {validation.password}
            </label>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "green", fontWeight: "800" }}
            >
              {validation.passwordSuccess}
            </label>
          </div>

          <label style={{ fontWeight: "bold" }}>Confirm Password</label>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            name="password_confirmation"
            label="Confirm Password"
            value={state.password_confirmation}
            onChange={handleChange}
            onBlur={passwordConfirmValidator}
            required
            sx={{ mb: 2, mt: 1 }}
          />
          <div>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "red", fontWeight: "800" }}
            >
              {validation.password_confirmation}
            </label>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "green", fontWeight: "800" }}
            >
              {validation.password_confirmationSuccess}
            </label>
          </div>


          <label style={{ fontWeight: "bold" }}>Username</label>
          <TextField
            type="text"
            fullWidth
            name="username"
            label="Username"
            required
            value={state.username}
            onChange={handleChange}
            onBlur={usernameValidator}
            sx={{ mb: 2, mt: 1 }}
          />
          <div>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "red", fontWeight: "800" }}
            >
              {validation.username}
            </label>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "green", fontWeight: "800" }}
            >
              {validation.usernameSuccess}
            </label>
          </div>

          <label style={{ fontWeight: "bold" }}>Display Name</label>
          <TextField
            type="text"
            fullWidth
            name="displayname"
            label="Display Name"
            required
            value={state.displayname}
            onChange={handleChange}
            sx={{ mb: 2, mt: 1 }}
          />
          <div>
          <FormControl>
            <FormLabel sx={{ fontWeight: 800 }} id="demo-row-radio-buttons-group-label">Privacy Settings - How your content is viewed</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="privacy"
              value={state.privacy}
              onChange={handleChange}
              defaultValue="1"
            >
              <FormControlLabel value="1" control={<Radio  />} label="Public" />
              <FormControlLabel value="2" control={<Radio/>} label="Private" />
              <FormControlLabel value="3" control={<Radio/>} label="Friends" />

            </RadioGroup>
          </FormControl>
          </div>

          <label style={{ fontWeight: "bold" }}>First Name</label>
          <TextField
            type="text"
            fullWidth
            name="firstname"
            label="First Name"
            value={state.firstname}
            onChange={handleChange}
            required
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Last Name</label>
          <TextField
            type="text"
            fullWidth
            name="lastname"
            label="Last Name"
            value={state.lastname}
            onChange={handleChange}
            required
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Favorite Team</label>
          <Autocomplete
            sx={{
              color: "success.main",
            }}
            {...teamdataProps}
            id="open-on-focus"
            openOnFocus
            onChange={handleTeamChange}

            renderInput={(params) => (
              <TextField
                type="text"
                fullWidth
                onBlur={teamValidator}
                name="team"
                label="Favorite Team"
                value={state.team}
                required
                sx={{ mb: 2, mt: 1 }}
                {...params}
              />
            )}
          />
          <div>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "red", fontWeight: "800" }}
            >
              {validation.team}
            </label>
            <label
              sx={{ mb: 2, mt: 1 }}
              style={{ color: "green", fontWeight: "800" }}
            >
              {validation.teamSuccess}
            </label>
          </div>

          <label style={{ fontWeight: "bold" }}>Slogan</label>
          <TextField
            type="text"
            fullWidth
            name="slogan"
            label="Slogan"
            placeholder="Never take life seriously. Nobody gets out alive anyway"
            value={state.slogan}
            onChange={handleChange}
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Bio</label>
          <br />
          <textarea
            name="bio"
            placeholder="Tell a little about yourself"
            value={state.bio}
            onChange={handleChange}
            style={{
              width: "100%",
              height: "120px",
              padding: "12px 20px",
              boxSizing: "border-box",
              border: "2px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
              color: "#000",
              fontSize: "16px",
              resize: "none",
            }}
          />
          <br />

          <label style={{ fontWeight: "bold" }}>Mobile</label>

          <TextField
            type="text"
            fullWidth
            name="mobile"
            label="Mobile"
            placeholder="(999)"
            value={state.mobile}
            onChange={handleChange}
            style={{ mb: 2, mt: 1 }}
          />

          {/* <button type="submit" className="btn btn-primary">
                  SUBMIT <span className="glyphicon glyphicon-send"></span>
                </button> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
             sx={{
              py: 1.5,
              mt: 2,
              background: "#1877f2 !important",
              color: "#fff",
            }}
            disabled={
            state.email !== "" && 
            state.password !== "" && 
            state.password_confirmation !== "" &&
            state.username !== "" && 
            state.displayname  !== "" &&
            state.firstname !== "" && 
            state.lastname !== "" && state.team !== ""?false: true
          }
          >
            SUBMIT
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default Registration;
