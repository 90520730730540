import axios from "axios";
import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import "./Colors.css"
import { Button, TextField } from "@mui/material";



const Colors = () => {

    const [sources, setSources] = useState([])
    console.log("Colors Sources", sources)
    

    const [state, setState] = useState({
        first: "",
        second: "",
        third: ""
       
      });

    const handleSubmit = (event) => {
        event.preventDefault();
        setState({...state})
        console.log("Color State", state)

       
         
      }
    

    useEffect(() => {


        const url = `${process.env.REACT_APP_API_SERVER}api/v1/sourcenames`;


        axios
            .get(url)
            .then((response) => {
                setSources(response.data.data);

                console.log("Color Response", response);
            })
            .catch((response) => console.log(response));
    }, []);

    const handleChange = (event) => {
        console.log(event.target.value);
        
      };

    const showcolors = () => {

        let server
  
        server = "https://dm1r1mwnqdyan.cloudfront.net"

        return (
                <>
                {sources.map((source) => (
                   
                    <table border="0">
                    <tr key={source.id}>
                        
                        <td>
                            <Avatar
                                src={server + "/" + source.attributes.username + ".png"}
                                alt={source.attributes.first_name + " " + source.attributes.last_name}
                                sx={{ width: "250px", height: "250px", borderRadius: "0%", mr: "2px", ml: { xs: 0, sm: 3 } }}
                            />

                        </td>
                        <td>{source.attributes.first_name + " " + source.attributes.last_name}</td>
                        <td>
                            <TextField
                                variant="outlined"
                                type="text"
                                name={"first" + source.id}
                                onChange={handleChange}
                                label="Primary Color"
                                required
                                sx={{ mb: 2, mt: 1 }}
                            />
                        </td>
                        <td>
                            <TextField
                                variant="outlined"
                                type="text"
                                name={"second" + source.id}
                                onChange={handleChange}
                                label="Second Color"
                                sx={{ mb: 2, mt: 1 }}
                            />
                        </td>
                        <td>
                            <TextField
                                variant="outlined"
                                type="text"
                                name={"third"+ source.id}
                                label="Third Color"
                                onChange={handleChange}
                                sx={{ mb: 2, mt: 1 }}
                            />
                        </td>
                        <td>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    py: 1.5,
                                    mt: 2,
                                    background: "#1877f2 !important",
                                    color: "#fff",
                                }}
                            >
                                Update
                            </Button>
                        </td>
                        
                    </tr>
                    </table>
                   
                    

                ))}
                </>

            
        )
    }





    return (

        <div>
            <h1>Colors</h1>

            {showcolors()}</div>
    )
}

export default Colors