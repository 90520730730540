import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export default function LoadingSpinner() {
  return (
    <Box sx={{ display:'flex', width:'100%', height:'100%', alignItems:'center',justifyContent:'center', mt:20 }}>
      <CircularProgress color="secondary" />
     
    </Box>
  );
}
