import Image from './Image'
import { Box, Button } from "@mui/material";
import { Avatar } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import Resizer from "react-image-file-resizer";



export const Resize = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [newImage,setnewImage] = React.useState("")
    const [image,setImage] = React.useState("")
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleFileSelect = (event) => {
      setSelectedFile(event.target.files[0])
      console.log("File",selectedFile)
    }

    const handleImageUpload = (event) => {
        event.preventDefault()
    
      }

      const setImageFile = (data) => {
        setImage(data.url)
        setImageDetails({
          image: data.url,
          filename: data.filename,
          imageid: data.imagefileid
        }
    
        )
      }

      const [imagedetails,setImageDetails] = useState(
        {
          image:"",
          filename: "",
          imageid: ""
    
        }
      )

      const fileChangedHandler = (event) =>{
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          try {
            Resizer.imageFileResizer(
              event.target.files[0],
              300,
              300,
              "JPEG",
              100,
              0,
              (uri) => {
                console.log(uri);
                setnewImage( uri );
              },
              "base64",
              200,
              200
            );
          } catch (err) {
            console.log(err);
          }
        }
      }

      const handleUploadFile = (event) => {
        event.preventDefault()
        let formData
        formData = new FormData();
        formData.append("image", newImage);
        const url = `${process.env.REACT_APP_API_SERVER}api/v1/upload`
            
        
          axios.post(url,formData)
          .then((response) => {
            console.log("Upload Response", response.data)
            setImage(response.data.url)
            setImageFile(response.data)
           
           
          })
          .catch((response) =>
            console.log("Error Failed Upload response", response)
          );
          //const response = axios({
           // method: "post",
            //url: "https://api.cloudflare.com/client/v4/accounts/a3e4b4c9ee93f2ba5f3dfbee8f8fb17c/images/v1",
            //data: {...formData.getHeaders(),
            //headers: {formData,
             // Authorization: "Bearer 9Xuc0qjgzyGPy0iotWp29NzOMPJSW9a1AEB1gITI"
             //}},
         // });
          //console.log("Image Upload Response",response)
       
      }

  return (
    <Box
    sx={{
        maxWidth: "580px",
        width: { xs: "100%", sm: "460px" },
        margin: "auto",
        background: "#fff",
        p: { xs: 2, sm: 3 },
        borderRadius: "12px",
        boxShadow: 24,
      }}
    
    >
         <Image
                handleClose={handleClose}
                open={open} 
                handleImageUpload={handleImageUpload}
                handleUploadFile={handleUploadFile}
                handleFileSelect={fileChangedHandler}
                setImageFile={setImageFile}
                selectedFile={selectedFile} 
                image={newImage}  
                newImage={newImage}         
              />
          <Avatar
              alt={"Unknown"}
              src={newImage}
              sx={{
                width: 150,
                height: 150,
                color: (theme) => theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer"
                
              }}

            />

              <img src={newImage}/>
    <Button
    type="button"
    variant="contained"
    onClick={handleOpen}
    sx={{
      py: 1.5,
      mt: 2,
      background: "#1877f2 !important",
      color: "#fff",
    }}
  >
    Upload Avatar
  </Button>
  </Box>
  )
}
