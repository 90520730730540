import * as React from 'react';
import Stack from '@mui/material/Stack';
//import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({handleClick, open, handleCloseSnack, statusShow }) {
  

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={ statusShow !=='Failed' ? "info" :'error'  } sx={{ width: '100%' }}>
           {statusShow}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
