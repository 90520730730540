import React, { useState, useEffect, Fragment } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import "./LumpItem.css";
import { linkHandler, timeSince } from "../Functions/Functions";
import ReactPlayer from 'react-player'
import Comments from "../Comments/Comments";


import "./LumpItem.css";
import {
  Avatar,
  Box,
  Divider,
  Typography,
  Link as MuiLink,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaThumbsUp,
  FaHeart,
  FaSmile,
  FaAngry,
  FaRegGrinAlt,
  FaVideo,
  FaRegImages,
} from "react-icons/fa";

const LumpItem = () => {
  const cookies = new Cookies();

  let server;
  server = "https://dm1r1mwnqdyan.cloudfront.net";

  let token
  token = cookies.get("token");

  const params = useParams();

  const [loaded, setLoaded] = useState(false);
  const [lump, setLump] = useState([]);
  const [source, setSource] = useState([]);


  useEffect(() => {
    const pathname = window.location.pathname; //returns the current url minus the domain name
    const lastitem = pathname.substring(pathname.lastIndexOf("/") + 1);

    let id;
    id = params.id;

    console.log("ID", id);

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/getlump/${id}`;

    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossdomain: true,
          Origin: "chunkysports.com",
        },
      })
      .then((response) => {
        console.log("Lump Response", response);
        if (response.status) {
          setLump(response.data.lump.data);
          setSource(response.data.source.data);
          setLoaded(true);
          console.log("Lump Response", response);
          console.log("Soure", source)
        }
        //console.log(response.data.data);
      })
      .catch((response) => console.log(response));
  }, []);

  const Lump = () => {
    //const datestring = timeSince(lump.attributes.created_at);

    return (

      <Grid item container xs={12}>
        <Grid item xs={12} key={lump.id} sx={{ mt: 2 }}>
          <Box
            sx={{
              bgcolor: "#e2e8f0",
              p: { xs: 2, sm: 3 },
              boxShadow: 3,
              borderRadius: "2px",
            }}
          >
            {/* Head of post */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 2,
              }}
            >
              <Link
                to={`/teams/${source.attributes.category}/${source.attributes.username}`}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                }}
              >
                <Avatar
                  src={server + "/logos50/" + source.attributes.username + ".png"}
                  alt={
                    source.attributes.first_name +
                    " " +
                    source.attributes.last_name
                  }
                  sx={{
                    width: "50px",
                    borderRadius: "50%",
                    ml: { xs: 0, sm: 3 },
                  }}
                />

                <Typography
                  sx={{ fontSize: 20, fontWeight: "bold", color: "#000" }}
                >
                  {`${source.attributes.first_name +
                    " " +
                    source.attributes.last_name
                    } -  ${source.attributes.category}`}
                </Typography>
              </Link>
            </Box>
            <Divider />

            {/* post head avatar and link text */}
            <Box
              sx={{
                my: { xs: 2, sm: 1.5 },
                alignItems: "center",
                display: "flex",
              }}
            >
              {!lump.attributes.youtube && (
                <Avatar
                  src={lump.attributes.image}
                  alt={lump.attributes.title}
                  sx={{
                    width: { xs: 40, sm: "50px" },
                    height: { xs: 40, sm: "50px" },
                    mr: 2,
                  }}
                />)}

              {lump.attributes.youtube && (
                <Avatar
                  src={source.attributes.logo}
                  alt={
                    source.attributes.first_name +
                    " " +
                    source.attributes.last_name
                  }
                  sx={{
                    width: { xs: 40, sm: "50px" },
                    height: { xs: 40, sm: "50px" },
                    mr: 2,
                  }}
                />)}

              <div className="flex flex-col items-start justify-start">
                <a
                  href={"#"}
                  target="_blank"
                  className="font-bold"
                  rel="noreferrer"
                  onClick={linkHandler(lump.id, lump.attributes.link)}
                >
                  {lump.attributes.title}
                </a>

                <p className="text-sm text-slate-500">{timeSince(lump.attributes.created_at)}</p>
              </div>
            </Box>

            {/* Post Description */}
            <Box sx={{}}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: "normal",
                  color: "#000",
                  my: { xs: 2, sm: 1.5 },
                }}
              >
                {lump.attributes.description}
              </Typography>

              {/* Post Main Image */}
              {!lump.attributes.youtube && (
                <a
                  href={"#"}
                  target="_blank"
                  className="font-bold"
                  rel="noreferrer"
                  onClick={linkHandler(lump.id, lump.attributes.link, token)}
                >
                  <Box
                    component="img"
                    src={lump.attributes.image}
                    alt={lump.attributes.title}
                    sx={{
                      width: "100%",
                      height: "100%",
                      "&:hover": { boxShadow: 3, borderRadius: "7px" },
                    }}
                  />
                </a>
              )}

              {lump.attributes.youtube && (
                <a
                  href={"#"}
                  target="_blank"
                  className="font-bold"
                  rel="noreferrer"
                  onClick={linkHandler(lump.id, lump.attributes.link)}
                >
                  <div className="video-container">
                    <ReactPlayer url={lump.attributes.link} controls={true} />
                  </div>
                </a>
              )}
            </Box>

            {/*  Rating Icons */}
            <Box>
              <Grid
                item
                container
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: 2,
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    maxWidth: "140px !important",
                    width: "100%",
                    alignItems: "center",
                    my: { xs: 1, sm: 0 },
                  }}
                >
                  <FaThumbsUp
                    size={20}
                    color="blue"
                    style={{ cursor: "pointer" }}
                  />
                  <FaHeart
                    size={20}
                    color="red"
                    style={{ cursor: "pointer" }}
                  />
                  <FaSmile size={20} style={{ cursor: "pointer" }} />
                  <FaAngry size={20} style={{ cursor: "pointer" }} />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    my: { xs: 1, sm: 0 },
                  }}
                >
                  <p className="font-bold cursor-pointer">
                    {Math.floor(Math.random() * 51)} Comments
                  </p>
                </Grid> */}
              </Grid>
            </Box>

            <hr className="m-0" />
            {/*  <div className="flex items-center justify-between font-bold">
              <span className="flex items-center justify-start gap-2 cursor-pointer">
                <FaVideo className="live" />
                <span className=""> Live </span>
              </span>
              <span className="flex items-center justify-start gap-2 cursor-pointer">
                <FaRegImages className="photo" />
                <span className=""> Photo </span>
              </span>
              <span className="flex items-center justify-start gap-2 cursor-pointer">
                <FaRegGrinAlt className="feeling" />
                <span className=""> Feeling </span>
              </span>
             
              </div> */}
            <Box>
              <Comments postId={lump.id} />
            </Box>

            {/* <div>
        {props.youtube && (
          <form>
            <input type="hidden" value={props.id} id="post-id" />
            <button
              type="button"
              onClick={() => clickHandler(props.id)}
              className="btn btn-primary"
            >
              Ban Lump
            </button>
          </form>
        )}
      </div> */}
          </Box>
        </Grid>
      </Grid>
    );
  };

  return <>{loaded && Lump()}</>;
};

export default LumpItem;
