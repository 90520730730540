import { Box, Button, TextField, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./UserProfile.css";
import Autocomplete from "@mui/material/Autocomplete";
import teamdata from "../Functions/TeamData.json";
import Cookies from "universal-cookie";
import FormLabel from '@mui/material/FormLabel';
import {Avatar} from "@mui/material";

const UserProfile = (props) => {
  const cookies = new Cookies();
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  const navigate = useNavigate();
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaded,setLoaded] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    username: "",
    mobile: "",
    firstname: "",
    lastname: "",
    slogan: "",
    bio: "",
    registrationErrors: "",
    team: "",
    privacy: "",
    image: ""
  });

  const [validation, setvalidation] = useState({
    email: "",
    emailSuccess: "",
    password: "",
    passwordSuccess: "",
    username: "",
    usernameSuccess: "",
    password_confirmation: "",
    password_confirmationSuccess: "",
    team: "",
    teamSuccess: "",
  });

  const DEBUG = props.debug

  

  const passwordConfirmValidator = () => {

    if(state.password === state.password_confirmation)
    {
      setvalidation({ ...validation, password_confirmationSuccess: "Password Confirmed", password_confirmation: "" });
      return true
    }
    else
    {
      setvalidation({ ...validation, password_confirmationSuccess: "", password_confirmation: "Password doesn't match" });
      return false
    }
  }

  const teamValidator = () => {

    if(state.team !== "")
    {
      setvalidation({ ...validation, teamSuccess: "Team selection is Good", team: "" });
      return true
    }
    else
    {
      setvalidation({ ...validation, team: "Must select one team", teamSuccess: "" });
      return false
    }
  }

  const teamdataProps = {
    options: teamdata,
    getOptionLabel: (option) =>
      option.attributes.first_name + " " + option.attributes.last_name,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    

  
    if(DEBUG === 1) console.log("Form submitted");

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/users`;
    if(DEBUG === 1) console.log("state", {
      ...state,
    });
    axios
      .post(url, {
        user: {
          ...state,
        },
      })
      .then((response) => {
        console.log("Successful response", response);
        if (response.data.isLoggedin) {
          props.handleSuccessfulAuth(response.data);
          if(DEBUG === 1) console.log("Successful Account")
          navigate("/");

        }
      })
      .catch((response) => console.log("Failed response", response));
    
  };

  const passwordValidator = () => {
    if (state.password.length !== 0) {
      setvalidation({ ...validation, password: "", passwordSuccess: "" });
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const minPassLength = 8

      const passwordLength = state.password.length;

      const upperpass = uppercaseRegExp.test(state.password)
      const lowerpass = lowercaseRegExp.test(state.password)
      const digitpass = digitsRegExp.test(state.password)

      let pass
      pass = 0
      if (passwordLength < minPassLength)
      {
        pass++
      }
      if (!upperpass)
      {
        pass++
      }
      if (!lowerpass)
      {
        pass++
      }
      if (!digitpass)
      {
        pass++
      }

      if (pass !== 0)
      {
        setvalidation({ ...validation, password: "Password Complexity: 8 min, 1 from Upper,Lower and Number", passwordSuccess: "" });
        return false
      }
      else
      {
        setvalidation({ ...validation, password: "", passwordSuccess: "Password is Good" });
        return true
      }

    }
  };

  const emailValidator = () => {
    setvalidation({ ...validation, email: "", emailSuccess: "" });

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!state.email || regex.test(state.email) === false) {
      setvalidation({
        ...validation,
        email: "Email Format is not valid",
        emailSuccess: "",
      });
      return false;
    } else {
      const email = { email: state.email };
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/isemailexists`;

      console.log("Email Address", state.email);
      axios
        .post(url, email)
        .then((response) => {
          if(DEBUG === 1) console.log("Successful response", response);
          if(DEBUG === 1) console.log("Email Results", response.data.successful);
          if (response.data.successful) {
            setvalidation({
              ...validation,
              emailSuccess: `${state.email} is available`,
              email: "",
            });
            return true;
          } else {
            setvalidation({
              ...validation,
              email: `${state.email} is NOT available`,
              emailSuccess: "",
            });
            return false;
          }
        })
        .catch((response) => console.log("Failed response", response));
    }
  };

  const usernameValidator = () => {
    setvalidation({ ...validation, username: "", usernameSuccess: "" });



      if (state.username.length < 5) {
      setvalidation({
        ...validation,
        username: "Username must be 5 characters or longer",
        usernameSuccess: "",
      });
      return false;
    } else {
      const username = { username: state.username };
      const url = `${process.env.REACT_APP_API_SERVER}api/v1/isusernameexists`;

      
      axios
        .post(url, username)
        .then((response) => {
          if(DEBUG === 1) console.log("Successful response", response);
         
          if (response.data.successful) {
            setvalidation({
              ...validation,
              usernameSuccess: `${state.username} is available`,
              username: "",
            });
            return true;
          } else {
            setvalidation({
              ...validation,
              username: `${state.username} is NOT available`,
              usernameSuccess: "",
            });
            return false;
          }
        })
        .catch((response) => console.log("Failed response", response));
    }
  };
  function handleTeamChange(event, value) {
    if (typeof value === "object" && value !== null) {
      setState({ ...state, team: value.attributes.username });
    }
    if(DEBUG === 1) console.log("State after setting team", state);
  }

  const handleChange = (event) => {
    if(DEBUG === 1) console.log(event.target.value);
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    let temp
    temp = cookies.get("token");
        

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/loggedin/${temp}`;


    axios
      .get(url)
      .then((response) => {
         setLoaded(true);
         if(DEBUG === 1) console.log("Response Data User", response.data);
        if (response.data.isLoggedin)
        {
          setState({
            email: response.data.user.email,
            username: response.data.user.username,
            mobile: response.data.user.mobile,
            firstname: response.data.user.firstname,
            lastname: response.data.user.lastname,
            slogan: response.data.user.slogan,
            bio: response.data.user.bio,
            displayname: response.data.user.displayname,
            registrationErrors: "",
            team: response.data.user.favorite,
            privacy: response.data.user.privacy,
            image: response.data.user.image

          })
        }
      })
      .catch((response) => console.log(response));
  }, []);

  return (
    <div>
      <Box
        sx={{
          maxWidth: "580px",
          width: { xs: "100%", sm: "460px" },
          margin: "auto",
          width: "100%",
          background: "#fff",
          p: { xs: 2, sm: 3 },
          borderRadius: "12px",
          boxShadow: 24,
        }}
      >
        <form
          onSubmit={handleSubmit}
          action=" "
          method="post"
          id="contact_form"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            User Profile
          </Typography>
          <br />
          <center><Avatar 
          src={state.image}
          alt={state.firstname}
                      sx={{
                        width: 150,
                        height: 150,
                        color: (theme) => theme.palette.primary.main,
                        textTransform: "uppercase",
                        fontSize: "30px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
          
          /> 

          <label style={{ fontWeight: "bold" }}>E-Mail: </label>
          <FormLabel sx={{ fontWeight: 600 , mt:3, ml: 1, mb: 3}}>{state.email}</FormLabel>
          <br />
                        

          <label style={{ fontWeight: "bold" }}>Username: </label>
         
          <FormLabel sx={{ fontWeight: 600, mt:3, ml: 1, mb: 3}}>{state.username}</FormLabel>
          </center>
          <br />
         
            <div>
          <label
            sx={{ mb: 2, mt: 1 }}
            style={{ color: "red", fontWeight: "800" }}
          >
            {validation.username}
          </label>
          <label
            sx={{ mb: 2, mt: 1 }}
            style={{ color: "green", fontWeight: "800" }}
          >
            {validation.usernameSuccess}
          </label>
          </div>

          <label style={{ fontWeight: "bold" }}>Display Name</label>
          <TextField
            type="text"
            fullWidth
            name="displayname"
            value={state.displayname}
            onChange={handleChange}
            sx={{ mb: 2, mt: 1 }}
          />
          <label style={{ fontWeight: "bold" }}>First Name</label>
          <TextField
            type="text"
            fullWidth
            name="firstname"
            
            value={state.firstname}
            onChange={handleChange}
            required
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Last Name</label>
          <TextField
            type="text"
            fullWidth
            name="lastname"
            
            value={state.lastname}
            onChange={handleChange}
            required
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Favorite Team</label>
          <Autocomplete
            sx={{
              color: "success.main",
            }}
            {...teamdataProps}
            id="open-on-focus"
            openOnFocus
            onChange={handleTeamChange}
           
            renderInput={(params) => (
              <TextField
                type="text"
                fullWidth
                onBlur={teamValidator}
                name="team"
               
                value={state.team}
                required
                sx={{ mb: 2, mt: 1 }}
                {...params}
              />
            )}
          />
          <div>
          <label
            sx={{ mb: 2, mt: 1 }}
            style={{ color: "red", fontWeight: "800" }}
          >
            {validation.team}
          </label>
          <label
            sx={{ mb: 2, mt: 1 }}
            style={{ color: "green", fontWeight: "800" }}
          >
            {validation.teamSuccess}
          </label>
          </div>

          <label style={{ fontWeight: "bold" }}>Slogan</label>
          <TextField
            type="text"
            fullWidth
            name="slogan"
            
            placeholder="Never take life seriously. Nobody gets out alive anyway"
            value={state.slogan}
            onChange={handleChange}
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Bio</label>
          <br />
          <textarea
            name="bio"
            placeholder="Tell a little about yourself"
            value={state.bio}
            onChange={handleChange}
            style={{
              width: "100%",
              height: "120px",
              padding: "12px 20px",
              boxSizing: "border-box",
              border: "2px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
              color: "#000",
              fontSize: "16px",
              resize: "none",
            }}
          />
          <br />

          <label style={{ fontWeight: "bold" }}>Mobile</label>

          <TextField
            type="text"
            fullWidth
            name="mobile"
            placeholder="(999)"
            value={state.mobile}
            onChange={handleChange}
            style={{ mb: 2, mt: 1 }}
          />

          {/* <button type="submit" className="btn btn-primary">
                  SUBMIT <span className="glyphicon glyphicon-send"></span>
                </button> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              py: 1.5,
              mt: 2,
              background: "#1877f2 !important",
              color: "#fff",
            }}
          >
            SUBMIT
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default UserProfile;
