import React, { useState, useEffect } from "react";
// import {Box } from "@mui/material/";
import Box from "@mui/material/Box";
import "./Drawer.css"
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MailIcon from "@mui/icons-material/Mail";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChatArea from "../ChatArea/ChatArea";
import { useMediaQuery } from "react-responsive";
import { BsFillChatRightTextFill } from "react-icons/bs";
import { Avatar, Grid, Button, Tooltip } from "@mui/material";
import CustomAppBar from "./AppBar";
import { Link, useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Scrollbar from "../Scrollbar/Scrollbar";
import Login from "../Login/Login";
import LogOut from "../Modal";
import { useAuth } from "../../context/AuthContext";
import SimpleBar from "simplebar-react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SaveIcon from '@mui/icons-material/Save';

export default function DrawerContent({
  currentUser,
  user,
  hendleNavigate,
  handleOpen,
  handleClose,
  firebaseLogout,
  handleLogoutClick,
  open,
  userLoginData,
  handleDrawerToggle,
  navigate,
  handleInputChange,
  teamdataProps,
  handleSummaryClick,
  handleOpenTeam,
  DEBUG
}) {


  // console.log(currentUser)

  if (DEBUG === 2) console.log("userLoginData in Content", userLoginData)
  let server

  server = "https://dm1r1mwnqdyan.cloudfront.net"

  if (user === undefined) {
    if (DEBUG === 2) console.log("User is undefined")
    user = {
      email: "unknown",
      id: 9999,
      image: ""
    }
  }
  else {
    if (DEBUG === 2) console.log("User is defined", user)
    if (DEBUG === 2) console.log("current user", currentUser)
  }


  return (

    <Box sx={{ overflow: { xs: 'auto', md: "hidden" }, height: '100vh' }}>
      <Scrollbar>
        <Box sx={{ overflow: 'auto', height: '100%' }}>
          <Toolbar />
          <Divider />
          <Box
            sx={{
              display: "flex",
              height: 200,
              bgcolor: (theme) => theme.palette.primary.maxLight,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={
                user.email || currentUser
                  ? user.email || currentUser.email
                  : "Login"
              }
            >
              <Avatar
                src={!currentUser ? user.image : currentUser.image}
                alt={!currentUser ? user.image : currentUser.image}
                sx={{
                  width: 110,
                  height: 110,
                  color: (theme) => theme.palette.primary.main,
                  textTransform: "uppercase",
                  fontSize: "30px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Box>

          <Tooltip title="Type team name or mascot" placement="top-start">
            <Autocomplete
              sx={{
                color: 'success.main',
              }}
              {...teamdataProps}
              id="open-on-focus"
              openOnFocus
              onChange={handleInputChange}
              renderInput={(params) => (
                <TextField sx={{
                  color: '#ffffff',
                  bgcolor: '#ffffff',
                  width: '95%',
                  ml: "10px",
                  mt: "3px"

                }}
                  {...params} label="Search Team or Mascot" variant="standard" />
              )}
            />
          </Tooltip>

          <List sx={{ mt: 5 }}>
            <ListItem
              button
              onClick={() => {
                hendleNavigate();
                handleDrawerToggle();
              }}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <Typography>All Teams</Typography>
            </ListItem>

            {user.username || currentUser ? (
              <Box to="/signup">
                <ListItem
                  button
                  onClick={handleSummaryClick}

                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <Typography>Summary</Typography>

                </ListItem>
                <ListItem
                  onClick={() => {
                    handleOpenTeam();
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <Typography>Manage Teams</Typography>
                </ListItem>
                <ListItem
                  onClick={() => {
                    handleOpenTeam();
                  }}
                >
                  <ListItemIcon>
                    <SaveIcon />
                  </ListItemIcon>
                  <Typography>Saved</Typography>
                </ListItem>
                <LogOut
                  handleClose={handleClose}
                  open={open}
                  handleLogoutClick={
                    currentUser ? firebaseLogout : handleLogoutClick
                  }
                />

              </Box>
            ) : (
              <Box>
                <Link to="/login">
                  <ListItem onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <Typography>Login</Typography>
                  </ListItem>
                </Link>
                <Link to="/signup">
                  <ListItem button onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <Typography>SignUp</Typography>
                  </ListItem>
                </Link>
                {/* <Login handleClose={handleClose} open={open} /> */}
              </Box>
            )}
          </List>


          <Typography sx={{ mt: 4, px: 2, fontSize: 20, fontWeight: "bold" }}>
            Teams {(userLoginData || currentUser) && userLoginData?.length}
          </Typography>
          <Divider />
          <SimpleBar style={{ height: 320, }} >
            <List sx={{ overflow: 'auto' }}>
              {userLoginData || currentUser ? (
                userLoginData?.map((text, index) => (
                  <ListItem
                    button
                    key={text.username}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon>
                      <Box
                        component="img"
                        src={`${server}/logos30/${text.username}.png`}
                        alt={text.username}
                        sx={{ width: 30, height: 30 }}
                      />
                    </ListItemIcon>

                    <Link to={`/teams/${text.category}/${text.username}`}>
                      <Typography>
                        {text.first_name} {text.last_name}
                      </Typography>
                    </Link>
                  </ListItem>
                ))
              ) : (
                <Button
                  sx={{
                    width: "100%",
                    py: 1,
                    mt: 2,
                    fontSize: 18,
                    "&:hover": {
                      color: (theme) => theme.palette.primary.original,
                      textDecoration: "underline",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    navigate("/login");
                    handleDrawerToggle();
                  }}
                >
                  Login Please
                </Button>
              )}
            </List>
          </SimpleBar>

        </Box>
        <Typography sx={{ mt: 2, ml: 2, fontSize: 12, fontWeight: "bold" }}>
          Links:
        </Typography>
        <Link to="/terms">
          <Typography sx={{ ml: 2, fontSize: 12, fontWeight: "bold" }}>
            Terms and Conditions
          </Typography>
        </Link>
        <Link to="/privacy">
          <Typography sx={{ ml: 2, fontSize: 12, fontWeight: "bold" }}>
            Privacy
          </Typography>
        </Link>
      </Scrollbar>



    </Box>
  );
}
