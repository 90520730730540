import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import CustomizedSnackbars from "../Modal/Snackbar";
import "./Loginform.css";

const MButton = ({ onClick, title, sx }) => (
  <Button
    onClick={onClick}
    fullWidth
    variant="contained"
    sx={{
      py: 1.5,
      mt: 2,
      background: "#1877f2 !important",
      color: "#fff",
      ...sx,
    }}
  >
    {title}
  </Button>
);

const Loginform = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
    username: "",
  });

  

  const [statusShow, setStatusShow] = useState("Form submitted");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    handleClick();
    if (props.DEBUG === 1) console.log("Form submitted");

    const url = `${process.env.REACT_APP_API_SERVER}api/v1/login`;

    axios
      .post(url, {
        user: {
          email: state.email,
          password: state.password,
          username: state.username,
        },
      })
      .then((response) => {
        if (props.DEBUG === 1) console.log("Successful response", response.data.status);
        setStatusShow(response.data.status);
        if (response.data.isLoggedin) {
          props.handleSuccessfulAuth(response.data);
          //   let site
          //   site = "http://" + window.location.host + "/"
          //   console.log("Site:", site)
          //  window.location.href = site;
          navigate("/");
        }
      })
      .catch((response) => console.log("Failed response"));
    e.preventDefault();
  };

  const handleChange = (event) => {
    if (props.DEBUG === 1) console.log(event.target.value);
    setState({ ...state, [event.target.name]: event.target.value });
  };

  // Social Login Functions
  const {
    signInWithGoogle,
    currentUser,
    signInWithFacebook,
    signInWithTwitter,
  } = useAuth();
  if (props.DEBUG === 1) console.log("currentUser", currentUser);

  const googleAuth = () => {
    signInWithGoogle()
      .then((user) => {
        if (props.DEBUG === 1) console.log("User",user.user);


        navigate("/home");
      })
      .catch((error) => console.log(error.message));
  };
  const facebookAuth = () => {
    signInWithFacebook()
      .then((user) => {
        if (props.DEBUG === 1) console.log(user);
        navigate("/home");
      })
      .catch((error) => console.log(error.message));
  };
  const twitterAuth = () => {
    signInWithTwitter()
      .then((user) => {
        if (props.DEBUG === 1) console.log(user);
        navigate("/home");
      })
      .catch((error) => console.log(error.message));
  };

  return (
    <div>
      <Box
        sx={{
          maxWidth: "460px",
          width: { xs: "100%", sm: "460px" },
          margin: "auto",
          background: "#fff",
          p: { xs: 2, sm: 3 },
          borderRadius: "12px",
          boxShadow: 24,
        }}
      >
        <form
          // onSubmit={handleSubmit}
          action=" "
          method="post"
          id="contact_form"
        >
          <fieldset>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              Login
            </Typography>
            <br />

            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>E-Mail</label>

              <TextField
                variant="outlined"
                fullWidth
                className="form-control"
                type="email"
                name="email"
                label="E-Mail Address"
                value={state.email}
                onChange={handleChange}
                required
                sx={{ mb: 2, mt: 1 }}
              />
            </div>

            <div>
              <label style={{ fontWeight: "bold" }}>Password</label>

              <TextField
                variant="outlined"
                className="form-control"
                type="password"
                fullWidth
                name="password"
                label="Password"
                value={state.password}
                onChange={handleChange}
                required
                sx={{ mb: 2, mt: 1 }}
              />
            </div>
           
            <div className="form-group">
              <MButton
                // type="submit"
                onClick={handleSubmit}
                sx={{ background: "#1976d2 !important" }}
                title="Login"
              />
              <Link to="/signup">
                <Typography
                  sx={{
                    color: "#22bbff",
                    fontWeight: "bold",
                    textAlign: "center",
                    "&:hover": { color: "red" },
                    mt: 3,
                    textDecoration: "underline",
                  }}
                >
                  Don't have account? <span>Sign Up</span>
                </Typography>
              </Link>
              <CustomizedSnackbars
                handleClick={handleClick}
                handleCloseSnack={handleCloseSnack}
                open={open}
                statusShow={statusShow}
              />
              <MButton
                type="submit"
                sx={{ background: "#ea4335 !important" }}
                title="SignIn with google"
                onClick={googleAuth}
              />
              <MButton
                type="submit"
                sx={{ background: "#1877f2 !important" }}
                title="SignIn with FaceBook"
                onClick={facebookAuth}
              />
              <MButton
                type="submit"
                sx={{ background: "#1da1f2 !important" }}
                title="SignIn with Twitter"
                onClick={twitterAuth}
              />
            </div>
          </fieldset>
        </form>
      </Box>
    </div>
  );
};

export default Loginform;
