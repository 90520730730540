import React from "react";
import "./Middle.css";
import Mupdates from "../Mupdates/Mupdates";
import Status from "../Status/Status";
import Mnews from "../Mnews/Mnews";

const Middle = (props) => {
  return (
    <div className="middle">
      <Mupdates />
      <Status />
      <Mnews user={props.user} DEBUG={props.DEBUG}/>
    </div>
  );
};

export default Middle;
