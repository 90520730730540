import "./UserSidebar.css"
import React, { useEffect, useState } from "react";
import { SwipeableDrawer, Box, Button, TextField, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import Gravatar from 'react-gravatar'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import Autocomplete from "@mui/material/Autocomplete";
import teamdata from "../Functions/TeamData.json";




const UserSidebar = ({ DEBUG, user, isLoggedin, open, onClose }) => {
  const [loaded, setLoaded] = useState(false)
  const [gravatar, setGravatar] = useState("0")
  const [userState, setUserState] = useState({})
  const [gravatarEmail, setGravatarEmail] = useState("0")


  useEffect(() => {
    setLoaded(true)
    setUserState(user)
    if (user?.gravatar === false) {
      setGravatar("0")
    }
    else {
      setGravatar("1")
    }


  }, [])

  const handleGravatar = (event) => {
    if (DEBUG === 1) console.log("Gravatar Value:", event.target.name, event.target.value)
    setGravatar(event.target.value)
  }
  const handleGravatarEmail = (event) => {
    if (DEBUG === 1) console.log("Gravatar Value Email:", event.target.name, event.target.value)
    setGravatarEmail(event.target.value)
    if (event.target.value === "1")
    {
      userState.gravatar_email = userState.email
    }
  }

  const handleChange = (event) => {
    if (DEBUG === 1) console.log("User State:", event.target.name, event.target.value);
    setUserState({ ...userState, [event.target.name]: event.target.value });

  };

  const teamdataProps = {
    options: teamdata,
    getOptionLabel: (option) =>
      option.attributes.first_name + " " + option.attributes.last_name,
  };

  const getTeam = (username) => {
    var team = teamdata.filter(function (teamData) {
      console.log ("Username", username)
      return teamData.attributes.username = username

    })
    
    console.log ("Team Name", team)
    let teamName
   
    //teamName = team.attributes.first_name + " " + team.attributes.last_name
    //console.log ("Team Name", teamName)
    return teamName
  }



  const imageRequest = JSON.stringify({
    bucket: "chunkyimages",
    key: "02t1koihpragik1ep7b47m8qnsbp",
    edits: {
      height: 150,
      width: 150,
      fit: "cover"
    }
  })

  let buff = Buffer.from(imageRequest).toString('base64')

  /*console.log("Image Request",buff)*/


  if (DEBUG === 1) console.log("UserSideBar USER", user)
  if (DEBUG === 1) console.log("USERState", userState)
  function handleTeamChange(event, value) {
    if (typeof value === "object" && value !== null) {
      setUserState({ ...userState, team: value.attributes.username });
     
    }
   
  }

  
  return (


    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      disableSwipeToOpen={false}
      PaperProps={{ className: 'user-sidebar' }}
    >
      <Box>
        <IconButton onClick={onClose} className="user-close">
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bolder",
            textAlign: "center",
          }}
        >
          User Profile
        </Typography>
        <center>

          <FormControl>
            <FormLabel sx={{ fontWeight: 800 }} id="demo-row-radio-buttons-group-label">Gravatar Settings</FormLabel>

            {/* <Typography
                         
            sx={{
              fontSize: "18px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Gravatar</Typography> */}

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gravatar"
              value={userState.gravatar}
              onChange={handleGravatar}
              defaultValue="0"
            >


              <FormControlLabel value="1" checked={gravatar === "1"} control={<Radio />} label="Yes" />
              <FormControlLabel value="0" checked={gravatar === "0"} control={<Radio />} label="No" />


            </RadioGroup>
          </FormControl>




          {gravatar == "1" ? <>
            <center>
              <FormControl>
                <FormLabel sx={{ fontWeight: 300 }} id="demo-row-radio-buttons-group-label">Use same email as account?</FormLabel>

                {/* <Typography
                         
            sx={{
              fontSize: "18px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            Gravatar</Typography> */}

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="gravatar_email_control"
                  value={gravatarEmail}
                  onChange={handleGravatarEmail}
                  defaultValue="0"
                  sx={{ mb: 2, mt: 1 }}
                >


                  <FormControlLabel value="1" checked={gravatarEmail === "1"} control={<Radio />} label="Yes" />
                  <FormControlLabel value="0" checked={gravatarEmail === "0"} control={<Radio />} label="No" />


                </RadioGroup>
              </FormControl>
              <Gravatar
                email={userState.gravatar_email}
                size={150}
                className="gravatar"

              />
            </center>
            <TextField
              type="text"
              fullWidth
              name="gravatar_email"
              label="Gravatar Email"
              value={userState.gravatar_email}
              onChange={handleChange}
              defaultValue={userState?.gravatar_email ? userState?.gravatar_email : userState?.email}
              sx={{ mb: 2, mt: 1 }}
            />

          </>


            :
            <Avatar
              alt={`${user?.firstname} ${user?.lastname}`}
              src={user?.image150 ? user?.image150 : user?.image}
              sx={{
                width: 150,
                height: 150,
                color: (theme) => theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer"

              }}

            />



          }



          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {`${user?.firstname} ${user?.lastname}`}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {`${user?.username} - ${userState?.displayname}`}
          </Typography>
          <TextField
            type="text"
            fullWidth
            name="displayname"
            label="Display Name"
            value={userState.displayname}
            onChange={handleChange}
            defaultValue={userState?.displayname ? userState?.displayname : ""}
            sx={{ mb: 1, mt: 3 }}
          />
          <TextField
            type="text"
            fullWidth
            name="slogan"
            label="Slogan"
            placeholder="Never take life seriously. Nobody gets out alive anyway"
            value={userState.slogan}
            defaultValue={userState?.slogan ? userState?.slogan : ""}
            onChange={handleChange}
            sx={{ mb: 2, mt: 1 }}
          />

          <label style={{ fontWeight: "bold" }}>Bio</label>
          <br />
          <textarea
            name="bio"
            placeholder="Tell a little about yourself"
            value={userState.bio}
            defaultValue={userState?.bio ? userState?.bio : ""}
            onChange={handleChange}
            style={{
              width: "100%",
              height: "120px",
              padding: "12px 20px",
              boxSizing: "border-box",
              border: "2px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
              color: "#000",
              fontSize: "16px",
              resize: "none",
            }}
          />

<Autocomplete
            sx={{
              color: "success.main",
            }}
            {...teamdataProps}
            id="open-on-focus"
            openOnFocus
            onChange={handleTeamChange}
            

            renderInput={(params) => (
              <TextField
                type="text"
                fullWidth
                name="team"
                label="Favorite Team"
                value={userState.team}
                defaultValue={getTeam(userState.team)}
                required
                sx={{ mb: 2, mt: 1 }}
                {...params}
              />
            )}
          />

          <TextField
            type="text"
            fullWidth
            name="mobile"
            label="Mobile"
            placeholder="(999)999-9999"
            value={userState.mobile}
            onChange={handleChange}
            sx={{ mb: 2, mt: 3 }}
          />
        </center>
      </Box>
    </SwipeableDrawer>
  )

}

export default UserSidebar