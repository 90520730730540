import React from 'react'
import {Avatar, Typography} from "@mui/material";
import "./TeamLogo.css"

const TeamLogo = ({username, last, first, server, logo}) => {
  
  return (
    <div className="teamlogo_summary">
       <Avatar
            src={logo}
            alt={first + " " + last}
            sx={{ width: "50px", borderRadius: "0%", mr: "2px", ml:{xs:0 , sm:3 } }}
          />
      <Typography sx={{ fontSize: 20, fontWeight: "bold", color: "#fff" }}>
            {`${first} ${last}`}
       </Typography>
      
    </div>
  )
}

export default TeamLogo